import { lazy } from 'react';
import Landing from './Landing';
import MultipleReviewSites from './MultipleReviewSites';
import SupportForTeams from './SupportForTeams';
import BusinessAnalytics from './BusinessAnalytics';
import SocialMediaEngagement from './SocialMediaEngagement';
import MoreEffectiveReviews from './MoreEffectiveReviews';
import ServiceRecovery from './ServiceRecovery';
import authRoles from '../../auth/authRoles';

const LandingConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: '/',
      element: <Landing />,
    },
    {
      path: 'multiple-review-sites',
      element: <MultipleReviewSites />,
    },
    {
      path: 'support-for-teams',
      element: <SupportForTeams />,
    },
    {
      path: 'business-analytics',
      element: <BusinessAnalytics />,
    },
    {
      path: 'social-media-engagement',
      element: <SocialMediaEngagement />,
    },
    {
      path: 'more-effective-reviews',
      element: <MoreEffectiveReviews />,
    },
    {
      path: 'service-recovery',
      element: <ServiceRecovery />,
    },
  ],
};

export default LandingConfig;
