import withReducer from 'app/store/withReducer';
import { Typography } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import reducer from './store';

function MultipleReviewSites() {
  return (
    <div style={{ backgroundColor: '#FAFBFC' }}>
      <Header />
      <div className="bg-darkgreen lg:pb-96 pb-36 lg:pt-96 pt-36">
        <div className="container md:px-120 px-16">
          <div className="lg:flex justify-between items-center">
            <div>
              <h1 className="font-semibold md:text-48 text-36 text-white mb-28">
                Multiple Review Sites
              </h1>
              <Typography className="text-white text-18 font-medium max-w-640">
                Managing reviews on multiple platforms from Quick Reviews offers several significant
                benefits for businesses
              </Typography>
            </div>
            <img
              src="/assets/images/pages/MultipleReviewSites.png"
              alt="bad"
              className="mx-auto w-auto h-auto max-w-360 relative -mb-288 hidden lg:inline-block"
            />
          </div>
        </div>
      </div>
      <div className="mb-48">
        <div className="container md:px-120 px-16">
          <h2 className="text-24 font-semibold md:mt-68 mt-36 mb-28">Key Benefits:</h2>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">01</span> Time-saving:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Instead of logging into multiple platforms individually, businesses can access and
            manage reviews from Google, Yelp, Zomato, and others all in one place. This saves time
            and streamlines the review management process.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">02</span> Centralized Dashboard:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Businesses can view all their reviews across various platforms in a centralized
            dashboard. This provides a comprehensive overview of their online reputation and allows
            for easier monitoring and analysis.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">03</span> Consistent Responses:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            With all reviews accessible in one app, businesses can ensure consistent responses to
            customer feedback across different platforms. This helps maintain brand voice and
            messaging integrity.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">04</span> Efficient Monitoring:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Quick Reviews allows businesses to monitor reviews in real-time across multiple
            platforms. They can receive notifications for new reviews and respond promptly,
            addressing customer concerns and feedback in a timely manner.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">05</span> Enhanced Customer Engagement:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            By managing reviews on all platforms from one app, businesses can engage with customers
            more efficiently. They can respond to reviews promptly, demonstrate responsiveness, and
            build stronger relationships with customers.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">06</span> Comprehensive Reporting:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Features that allow businesses to track review trends and performance metrics across
            different platforms. This data-driven insight can inform decision-making and marketing
            strategies.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">07</span> Improved Reputation Management:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            With the ability to manage reviews on multiple platforms from one app, businesses can
            proactively manage their online reputation. They can address negative feedback promptly,
            highlight positive reviews, and effectively shape their brand perception across various
            channels.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">08</span> Scalability:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            As businesses grow and expand their online presence, Quick Reviews can scale to
            accommodate the increasing number of review platforms they engage with. This scalability
            ensures that businesses can effectively manage their online reputation regardless of
            their size or industry.
          </Typography>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MultipleReviewSites;
