import { Button, Typography } from '@mui/material';
import _ from '@lodash';
import { useEffect, useState, useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import PhoneInput from 'react-phone-input-2';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Link, animateScroll as scroll } from 'react-scroll';
import Icon from '@mui/material/Icon';
import { publicIpv4 } from 'public-ip';
import {
  getEncryptedData,
  getDecryptData,
  handleApiRequest,
  handleAuthRequest,
} from '../common/common';
import Footer from '../landing/Footer';
import LandingQuery from '../query/landingDetail';
import WelcomeQuery from '../query/welcome';
import Header from '../landing/Header';

const defaultValues = {
  firstName: '',
  lastName: '',
  agencyName: '',
  phone_number: '',
  mail: '',
  address: '',
  agencyUrl: '',
};
function Partner() {
  const [phone, setPhone] = useState();
  const [phoneValue, setPhoneValue] = useState();
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [doneAgentAccount, setDoneAgentAccount] = useState(false);
  const [emailOTPVerify, setEmailOTPVerify] = useState(false);
  const [submitData, setSubmitData] = useState({});
  const [ipBasedData, setIpBasedData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [agentEmail, setAgentEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [otpRefreshIcon, setOtpRefreshIcon] = useState(false);
  const [resendOtpStatus, setResendOtpStatus] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [domainLoading, setDomainLoading] = useState(false);
  const [domainAvailable, setDomainAvailable] = useState(false);

  const handleOtpChange = (newValue) => {
    setOtp(newValue);
  };

  const schema = yup.object().shape({
    firstName: yup
      .string()
      .required('First Name is required')
      .max(15, 'First Name is too Long - should be 15 Characters Maximum.')
      .matches(
        /^[a-zA-Z]+$/,
        '* Special characters, Space and Numbers are not allowed in firstName'
      ),
    lastName: yup
      .string()
      .required('Last Name is required')
      .max(15, 'Last Name is too Long - should be 15 Characters Maximum.')
      .matches(
        /^[a-zA-Z]+$/,
        '* Special characters, Space and Numbers are not allowed in lastName'
      ),
    agencyName: yup.string().required('Agency Name is required'),
    phone_number: yup
      .string()
      .required('Phone number is required')
      .min(10, 'Phone number is too short - should be 10 digit.'),
    mail: yup.string().required('Email Address is required').email('Enter a valid Email').trim(),
    address: yup.string().required('Address is required').trim(),
  });

  const { control, formState, handleSubmit, reset, setValue, getValues, watch, trigger } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const form = watch();

  const { isValid, dirtyFields, errors } = formState;

  useEffect(() => {
    if (!ipBasedData) {
      const ipAddress = publicIpv4();
      const ipAddressData = async () => {
        const payload = {
          query: LandingQuery.getLocation,
          variables: {
            data: getEncryptedData(`{"ipAddress": "${await ipAddress}"}`),
          },
        };
        const locationResponse = await handleApiRequest(payload);
        if (locationResponse?.getLocation?.data) {
          const locationData = locationResponse?.getLocation?.data;
          if (locationData) {
            setIpBasedData(JSON.parse(getDecryptData(locationData)));
          }
        }
      };
      ipAddressData();
    }
  }, [ipBasedData]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(interval);
        setResendOtpStatus(false);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const handlePhoneChange = (event, data, value, formattedValue) => {
    setPhoneValue(event);
    setPhone({
      ...data,
      phoneNumber: parseInt(event.slice(data?.dialCode?.length), 10),
    });
    setValue('phone_number', event.slice(data?.dialCode?.length), {
      shouldDirty: true,
      shouldValidate: true,
    });
    trigger('phone_number');
  };

  const handleEmail = (e) => {
    const email = /\S+@\S+\.\S+/.test(e);
    setAgentEmail(e);
    setDomainLoading(true);
    if (email === true) {
      const payload = {
        query: LandingQuery.checkAndVerifyEmailStatus,
        variables: { email: e },
      };
      const result = handleApiRequest(payload);
      if (result?.checkAndVerifyEmailStatus?.status === 200) {
        enqueueSnackbar(result?.checkAndVerifyEmailStatus?.message, {
          variant: 'success',
          autoHideDuration: 3000,
        });
        setDomainLoading(false);
        setDomainAvailable(true);
      } else {
        enqueueSnackbar(result?.checkAndVerifyEmailStatus?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
        setDomainLoading(false);
      }
    }
  };

  const onSubmit = async (data) => {
    try {
      setRefreshIcon(true);
      setAgentEmail(data.mail);

      const payload = {
        query: LandingQuery.checkAndVerifyPhoneNumber,
        variables: {
          phoneNumber: phone?.phoneNumber,
        },
      };
      const result = await handleApiRequest(payload);
      setRefreshIcon(false);
      if (
        result?.checkAndVerifyPhoneNumber?.status === 200 &&
        result?.checkAndVerifyPhoneNumber?.available === false
      ) {
        enqueueSnackbar(result?.checkAndVerifyPhoneNumber?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }

      if (result?.checkAndVerifyPhoneNumber?.available === true) {
        setRefreshIcon(true);
        const sentOtpEmail = await sendEmailOTP(data.mail);
        setRefreshIcon(false);
        if (sentOtpEmail?.status === 200) {
          setEmailOTPVerify(true);
          setSeconds(59);
        }
      }

      setSubmitData(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  async function submitOtp() {
    try {
      let result;

      if (agentEmail && otp) {
        setOtpRefreshIcon(true);
        const otpValue = parseInt(otp, 10);
        const data = getEncryptedData(`{"email": "${agentEmail}", "otp": ${otpValue}}`);
        const payload = {
          query: WelcomeQuery.verifyOTP,
          variables: { data },
        };
        result = await handleAuthRequest(payload);
        if (result.verifyOTP?.status === 200) {
          setOtp('');
          setOtpRefreshIcon(false);
          setEmailOTPVerify(false);
          setSeconds(0);
          setRefreshIcon(true);

          const payloadSignup = {
            query: LandingQuery.signupAgent,
            variables: {
              agencyAddress: submitData.address,
              agencyName: submitData.agencyName,
              email: submitData.mail,
              firstName: submitData.firstName,
              lastName: submitData.lastName,
              phoneNumber: {
                countryCode: `+${phone?.dialCode}`,
                phoneNumber: phone?.phoneNumber,
              },
              countryId: ipBasedData?.countryId,
              agencyUrl: submitData.agencyUrl,
            },
          };

          const resultSignup = await handleAuthRequest(payloadSignup);
          setRefreshIcon(false);
          if (resultSignup?.signupAgent?.status === 200) {
            enqueueSnackbar(resultSignup?.signupAgent?.message, {
              variant: 'success',
              autoHideDuration: 3000,
            });
            setRefreshIcon(false);
            setDoneAgentAccount(true);
            reset(defaultValues);
            setPhone();
            setPhoneValue();
          } else {
            enqueueSnackbar(resultSignup?.signupAgent?.message, {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
        } else {
          enqueueSnackbar(result.verifyOTP?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
          setOtpRefreshIcon(false);
        }
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setOtpRefreshIcon(false);
      setEmailOTPVerify(false);
    }
  }

  async function sendEmailOTP(email) {
    try {
      let res;
      if (email) {
        const emailPayload = {
          query: WelcomeQuery.checkEmail,
          variables: {
            email,
          },
        };
        const result = await handleApiRequest(emailPayload);

        if (result?.checkEmail?.status === 200 && result?.checkEmail?.available === true) {
          const emailData = {
            query: WelcomeQuery.sendOTPOnEmailVerification,
            variables: { email },
          };
          res = await handleAuthRequest(emailData);
          if (res?.sendOTPOnEmailVerification?.status === 200) {
            enqueueSnackbar(res?.sendOTPOnEmailVerification?.message, {
              variant: 'success',
              autoHideDuration: 3000,
            });
          }
        } else {
          enqueueSnackbar(result?.checkEmail?.message, {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
      return res?.sendOTPOnEmailVerification;
    } catch (error) {
      console.error('Error:', error);
      return error;
    }
  }

  const resendMobileOTP = async () => {
    setSeconds(59);
    // await sendEmailOTP(agentEmail);
    const res = await handleAuthRequest({
      query: WelcomeQuery.sendOTPOnEmailVerification,
      variables: { email: agentEmail },
    });
    if (res?.sendOTPOnEmailVerification?.status === 200) {
      enqueueSnackbar(res?.sendOTPOnEmailVerification?.message, {
        variant: 'success',
        autoHideDuration: 3000,
      });
    } else {
      enqueueSnackbar(res?.sendOTPOnEmailVerification?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handleClose = useCallback(() => {
    setEmailOTPVerify(false);
  }, []);

  function handleRemove() {
    setEmailOTPVerify(false);
    setSeconds(59);
  }

  return (
    <div>
      <Header />
      <div className="text-center">
        <h2 className="md:text-44 text-20 font-bold text-black md:mt-96 mt-36">
          <span className="block">Achieve More Together: Partner</span>{' '}
          <span className="block">With Quick Reviews</span>
        </h2>
        <Typography className="md:text-18 text-16 font-semibold text-black max-w-5xl mx-auto md:my-40 my-20">
          Partner with Quick Reviews to grow together and succeed. Let's work hand in hand to reach
          new heights, expand opportunities, and elevate your business to unprecedented levels of
          success.
        </Typography>
        <Button className="text-16 font-medium md:px-48" variant="contained" color="secondary">
          <Link
            to="partner-signup"
            role="button"
            className="hover:text-darkgreen-100 sm:text-white"
            spy
            smooth
            duration={500}
            activeClass="active"
          >
            Become A Partner
          </Link>
        </Button>
      </div>
      <div className="md:mt-96 mt-24">
        <div className="container">
          <div className="bg-darkgreen-50 mx-auto md:p-48 p-16 md:pb-0 pb-0">
            <h3 className="md:text-28 text-20 font-semibold text-darkgreen text-center">
              How Can We Support Our Partners?
            </h3>
            <Typography className="text-16 font-medium text-center text-black max-w-4xl mx-auto md:my-40 my-20">
              Discover how we go above and beyond to support our partners' success. From tailored
              assistance to resource sharing, we're committed to helping you thrive.
            </Typography>
            <div className="row md:flex md:-mx-24">
              <div className="md:w-2/6 md:px-24 px-0">
                <div className="mb-16 md:mb-48 aos-init aos-animate" data-aos="fade-up">
                  <div>
                    <img
                      src="/assets/images/partner/support-1.png"
                      alt="bad"
                      className="w-auto h-auto"
                      loading="lazy"
                    />
                  </div>
                  <div className="w-4/5">
                    <h3 className="text-16 md:text-18 font-bold mb-10 mt-16">
                      Tailored Assistance
                    </h3>
                    <Typography className="text-14 md:text-16 leading-7 text-grey-700 font-medium">
                      Personalized support from our team, ensuring solutions align with your unique
                      needs and objectives.
                    </Typography>
                  </div>
                </div>
                <div className="mb-16 md:mb-48 aos-init aos-animate" data-aos="fade-up">
                  <div>
                    <img
                      src="/assets/images/partner/support-2.png"
                      alt="bad"
                      className="w-auto h-auto"
                      loading="lazy"
                    />
                  </div>
                  <div className="w-4/5">
                    <h3 className="text-16 md:text-18 font-bold mb-10 mt-16">Resource Sharing</h3>
                    <Typography className="text-14 md:text-16 leading-7 text-grey-700  font-medium">
                      Access marketing materials, industry insights, and best practices to maximize
                      partnership impact.
                    </Typography>
                  </div>
                </div>
              </div>
              <div
                className="md:w-2/6 md:px-24 px-16 aos-init aos-animate flex items-end"
                data-aos="fade-up"
              >
                <img
                  src="/assets/images/partner/support.png"
                  alt="bad"
                  className="mx-auto my-24 md:my-0 w-auto h-auto"
                />
              </div>
              <div className="md:w-2/6 md:px-24 px-0">
                <div className="mb-16 md:mb-48 aos-init aos-animate" data-aos="fade-up">
                  <div>
                    <img
                      src="/assets/images/partner/support-3.png"
                      alt="bad"
                      className="w-auto h-auto"
                      loading="lazy"
                    />
                  </div>
                  <div className="w-4/5">
                    <h3 className="text-16 md:text-18 font-bold mb-10 mt-16">
                      Training & Education
                    </h3>
                    <Typography className="text-14 md:text-16 leading-7 text-grey-700  font-medium">
                      Gain access to training sessions and resources to enhance your skills and
                      knowledge.
                    </Typography>
                  </div>
                </div>
                <div className="mb-16 md:mb-48 aos-init aos-animate" data-aos="fade-up">
                  <div>
                    <img
                      src="/assets/images/partner/support-4.png"
                      alt="bad"
                      className="w-auto h-auto"
                      loading="lazy"
                    />
                  </div>
                  <div className="w-4/5">
                    <h3 className="text-16 md:text-18 font-bold mb-10 mt-16">
                      Continuous Support & Guidance
                    </h3>
                    <Typography className="text-14 md:text-16 leading-7 text-grey-700  font-medium">
                      Ongoing assistance from our team to ensure your success and address any
                      challenges.
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="md:mt-96 mt-24">
        <div className="container">
          <h3 className="md:text-28 text-20 font-semibold text-darkgreen text-center">
            Unlocking Growth Together
          </h3>
          <Typography className="text-16 font-medium text-center text-black max-w-4xl mx-auto md:my-40 my-20">
            Discover the power of partnership with our platform. Expand your reach, enhance
            credibility, and explore strategic collaboration opportunities to drive mutual growth
            and success.
          </Typography>
          <div className="grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 grid-cols-1 md:gap-28 gap-16 mt-84">
            <div className="bg-white rounded-md sm:py-20 sm:px-28 px-0 py-0">
              <img
                src="/assets/images/partner/grow-1.png"
                className="img-fluid sm:max-w-76 max-w-48 w-full mb-24"
                alt="..."
              />
              <h3 className="font-bold sm:text-18 text-16 mb-14">Customer Spotlights</h3>
              <Typography className="sm:text-16 font-medium text-grey-700">
                Hear from our valued customers and the impact our products or services have had on
                their lives. Explore inspiring stories and testimonials firsthand.
              </Typography>
            </div>
            <div className="bg-white rounded-md sm:py-20 sm:px-28  px-0 py-0">
              <img
                src="/assets/images/partner/grow-2.png"
                className="img-fluid sm:max-w-76 max-w-48 w-full mb-24"
                alt="..."
              />
              <h3 className="font-bold sm:text-18 text-16 mb-14">Insights and Reflections</h3>
              <Typography className="sm:text-16 font-medium text-grey-700">
                Gain insights and reflections from our founder, sharing personal anecdotes and
                lessons along the entrepreneurial journey. Join us in the Founder's Corner for
                wisdom.
              </Typography>
            </div>
            <div className="bg-white rounded-md sm:py-20 sm:px-28  px-0 py-0">
              <img
                src="/assets/images/partner/grow-3.png"
                className="img-fluid sm:max-w-76 max-w-48 w-full mb-24"
                alt="..."
              />
              <h3 className="font-bold sm:text-18 text-16 mb-14">Community Connection</h3>
              <Typography className="sm:text-16 font-medium text-grey-700">
                Explore our commitment to community building and fostering meaningful connections.
                Learn how we engage with our audience and give back to those who support us.
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div className="md:mt-96 mt-24">
        <div className="container">
          <div className="row md:flex items-center md:-mx-24">
            <div className="md:w-2/5 px-0 md:px-24 aos-init aos-animate">
              <h3 className="md:text-28 text-20 font-semibold text-darkgreen">
                Partnering With Us: A Seamless{' '}
                <span className="block">Process For Mutual Success</span>
              </h3>
              <Typography className="text-16 font-medium text-black md:mt-32 mt-16 border-b-2 border-dashed border-darkgreen pb-32 mb-44">
                Discover how easy it is to partner with us for mutual success. From exploring
                opportunities to formalizing agreements and launching collaborations, we guide you
                through every step of the process seamlessly.
              </Typography>
              <h4 className="md:text-18 text-16 font-bold mb-20 flex">
                <img
                  src="/assets/images/partner/hand.png"
                  className="img-fluid mr-12 max-w-32 h-24"
                  alt="..."
                />{' '}
                Explore Partnership Opportunities
              </h4>
              <Typography className="text-16 text-grey-700 font-medium">
                Discover the diverse range of partnership opportunities we offer, including
                sponsored content, affiliate marketing, and joint promotions, tailored to suit your
                business goals and objectives.
              </Typography>
              <h4 className="md:text-18 text-16 font-bold mb-20 flex mt-36">
                <img
                  src="/assets/images/partner/hand.png"
                  className="img-fluid mr-12 max-w-32 h-24"
                  alt="..."
                />
                Formalize the Agreement
              </h4>
              <Typography className="text-16 text-grey-700 font-medium">
                Finalize the partnership agreement, outlining terms and commitments, to establish a
                solid foundation for our collaboration and pave the way for mutual growth and
                success.
              </Typography>
            </div>
            <div className="md:w-3/5 px-24 aos-init aos-animate">
              <img
                src="/assets/images/partner/partners.png"
                className="img-fluid mr-0 ml-auto w-full max-w-640 sm:mt-0 mt-20"
                alt="..."
              />
            </div>
          </div>
        </div>
      </div>
      <div className="md:mt-96 mt-24 md:-mb-96 -mb-24" name="partner-signup">
        <div className="bg-darkgreen-50 md:py-92 py-24">
          <div className="container">
            <h3 className="md:text-28 text-20 font-semibold text-darkgreen text-center">
              Let's Work Together
            </h3>
            <Typography className="text-16 font-medium text-center text-black max-w-4xl mx-auto md:my-32 my-20">
              Take the first step towards collaboration and growth by filling out our partnership
              form below.
            </Typography>

            <form name="userDetails" onSubmit={handleSubmit(onSubmit)}>
              <div className="grid gap-16 md:gap-20 grid-cols-1 md:grid-cols-2">
                <div className="block">
                  <InputLabel id="firstName" className="text-16 font-medium mb-4 text-grey-900">
                    First Name<span className="text-red">*</span>
                  </InputLabel>
                  <Controller
                    name="firstName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="text"
                        placeholder="Enter your first name here"
                        error={!!errors.firstName}
                        helperText={errors?.firstName?.message}
                        InputProps={{
                          style: { backgroundColor: 'white' },
                        }}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="block">
                  <InputLabel id="agencyName" className="text-16 font-medium mb-4 text-grey-900">
                    Agency Name<span className="text-red">*</span>
                  </InputLabel>
                  <Controller
                    name="agencyName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="text"
                        placeholder="Enter your agency name here"
                        error={!!errors.agencyName}
                        helperText={errors?.agencyName?.message}
                        InputProps={{
                          style: { backgroundColor: 'white' },
                        }}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="block">
                  <InputLabel id="lastName" className="text-16 font-medium mb-4 text-grey-900">
                    Last Name<span className="text-red">*</span>
                  </InputLabel>
                  <Controller
                    name="lastName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="text"
                        placeholder="Enter your last name here"
                        error={!!errors.lastName}
                        helperText={errors?.lastName?.message}
                        InputProps={{
                          style: { backgroundColor: 'white' },
                        }}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="block">
                  <InputLabel id="agencyUrl" className="text-16 font-medium mb-4 text-grey-900">
                    Agency URL
                  </InputLabel>
                  <Controller
                    name="agencyUrl"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="text"
                        placeholder="Enter your agency URL here"
                        error={!!errors.agencyUrl}
                        helperText={errors?.agencyUrl?.message}
                        InputProps={{
                          style: { backgroundColor: 'white' },
                        }}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="block">
                  <div className="mb-20">
                    <InputLabel
                      id="phone_number"
                      className="text-16 font-medium mb-4 text-grey-900"
                    >
                      Phone Number<span className="text-red">*</span>
                    </InputLabel>
                    <div
                      className={`phone-input-container ${
                        errors.phone_number ? 'phone-input-error' : ''
                      }`}
                    >
                      <PhoneInput
                        placeholder="0000000000"
                        inputStyle={{
                          width: '100%',
                          height: '37px',
                          borderColor: errors.phone_number ? 'red' : '',
                        }}
                        className="w-full"
                        inputProps={{
                          name: 'phone_number',
                          required: true,
                        }}
                        name="phone_number"
                        required
                        error={!!errors.phone_number}
                        helperText={errors?.phone_number?.message}
                        value={phoneValue}
                        size="small"
                        country={ipBasedData?.isoCode?.toLowerCase()}
                        enableSearch="true"
                        onChange={handlePhoneChange}
                      />
                    </div>
                    {errors.phone_number && (
                      <Typography variant="caption" color="error">
                        {errors.phone_number.message}
                      </Typography>
                    )}
                  </div>
                  <div className="block">
                    <InputLabel id="mail" className="text-16 font-medium mb-4 text-grey-900">
                      Email Address<span className="text-red">*</span>
                    </InputLabel>
                    <Controller
                      name="mail"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="text"
                          placeholder="Enter your email address here"
                          // onChange={(e) => handleEmail(e?.target?.value)}
                          // value={agentEmail}
                          error={!!errors.mail}
                          helperText={errors?.mail?.message}
                          InputProps={{
                            style: { backgroundColor: 'white' },
                          }}
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                    {domainLoading && (
                      <CircularProgress
                        size={12}
                        className="text-darkgreen absolute mx-auto right-8 top-14 z-10"
                      />
                    )}
                    {domainAvailable && (
                      <span className="w-16 h-16 inline-block rounded-full align-middle text-center pt-2 absolute right-8 top-12 z-0 bg-darkgreen text-white text-12">
                        <Icon className="text-12">check</Icon>
                      </span>
                    )}
                  </div>
                </div>
                <div className="block">
                  <InputLabel id="address" className="text-16 font-medium mb-4 text-grey-900">
                    Agency Address<span className="text-red">*</span>
                  </InputLabel>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="text"
                        placeholder="Enter your agency address here"
                        error={!!errors.address}
                        helperText={errors?.address?.message}
                        InputProps={{
                          style: { backgroundColor: 'white' },
                        }}
                        variant="outlined"
                        multiline
                        rows={6}
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </div>
              </div>
              <div className="text-center mt-24">
                <Button
                  className="md:text-16 font-medium disabled:text-black rounded-md md:min-w-192 mx-10"
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={_.isEmpty(dirtyFields) || !isValid || refreshIcon}
                >
                  Submit
                  {refreshIcon && (
                    <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                  )}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Footer />
      {emailOTPVerify && (
        <Dialog
          classes={{
            paper: 'm-4 p-4 max-w-640 rounded-md',
          }}
          open={emailOTPVerify}
          onClose={() => {
            handleClose();
          }}
          onKeyDown={(event) => {
            if (event.keyCode === 27) {
              handleClose();
            }
          }}
          fullWidth
          maxWidth="md"
        >
          <DialogContent>
            <div className="text-center">
              <DialogTitle className="font-bold text-20 md:text-24 mb-2 p-2">
                <Icon
                  onClick={handleRemove}
                  className="text-32 absolute top-12 right-12 text-grey-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
                >
                  cancel
                </Icon>
                <img
                  src="/assets/images/partner/email.png"
                  className="img-fluid mx-auto max-w-136 mb-12"
                  alt="..."
                />
                Verify Your Email{' '}
              </DialogTitle>
              <DialogContentText className="text-black font-medium md:text-16 mb-28 max-w-480 mx-auto">
                We sent verification code to {agentEmail}.
                <span className="block">Please check your inbox and enter the code below.</span>
              </DialogContentText>
              <MuiOtpInput
                className="max-w-xs mx-auto"
                TextFieldsProps={{
                  size: 'medium',
                  placeholder: '-',
                  type: 'tel',
                }}
                type="number"
                gap={1}
                length={6}
                value={otp}
                onChange={handleOtpChange}
              />
              <div className="card">
                <div className="countdown-text flex items-center justify-center text-16 font-medium mt-20">
                  {seconds > 0 ? (
                    <p>The code can be resent in: 00:{seconds}</p>
                  ) : (
                    <p>Didn't receive the code?</p>
                  )}
                  {resendOtpStatus ? (
                    <CircularProgress
                      size={24}
                      className="text-12 md:text-14 ml-10 mt-1 text-darkgreen mx-auto"
                    />
                  ) : (
                    <Button
                      disabled={seconds > 0}
                      className="decoration-1 text-12 md:text-14 font-medium"
                      color="secondary"
                      style={{
                        color: seconds > 0 ? '#DFE3E8' : '#006B5A',
                      }}
                      onClick={resendMobileOTP}
                    >
                      Resend Code
                    </Button>
                  )}
                </div>
              </div>
              <Button
                className="px-32 md:px-52 max-w-sm md:text-18 font-semibold disabled:text-black mt-52"
                variant="contained"
                color="secondary"
                disabled={otp?.length !== 6 || otpRefreshIcon}
                onClick={submitOtp}
                aria-label="Request"
                type="button"
              >
                Verify Email{' '}
                {otpRefreshIcon && (
                  <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                )}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}

      {doneAgentAccount && (
        <Dialog
          classes={{
            paper: 'm-4 p-4 max-w-640 rounded-md',
          }}
          open={doneAgentAccount}
          onClose={() => {
            setDoneAgentAccount(false);
          }}
          onKeyDown={(event) => {
            if (event.keyCode === 27) {
              setDoneAgentAccount(false);
            }
          }}
          fullWidth
          maxWidth="md"
        >
          <DialogContent className="p-5">
            <div className="text-center">
              <DialogTitle className="font-bold text-20 md:text-24 mb-16 p-2 text-green">
                <div className="bg-green-A100 w-full h-112 mb-20 rounded-md" />
                <Icon
                  onClick={() => {
                    setDoneAgentAccount(false);
                  }}
                  className="text-32 absolute top-12 right-12 text-darkgreen-400 font-normal cursor-pointer hover:text-gray-700 focus:text-gray-700"
                >
                  cancel
                </Icon>
                <img
                  src="/assets/images/partner/done.png"
                  className="img-fluid mx-auto max-w-136 mb-12 relative -mt-72"
                  alt="..."
                />
                Thank You!{' '}
              </DialogTitle>
              <DialogContentText className="text-black font-medium md:text-16 mb-28 max-w-480 mx-auto">
                Thank you for submitting your partnership application! We'll review your information
                thoroughly and get back to you shortly with next steps.
              </DialogContentText>

              <Button
                className="px-32 md:px-52 md:text-18 font-semibold mb-32 bg-green text-white rounded-full"
                variant="contained"
                size="small"
                color="secondary"
                onClick={() => setDoneAgentAccount(false)}
                aria-label="Request"
                type="button"
              >
                Done
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default Partner;
