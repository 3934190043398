import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import settingsConfig from 'app/configs/settingsConfig';
import dashboardsConfigs from '../main/dashboards/dashboardsConfigs';
import pagesConfigs from '../main/pages/pagesConfigs';
import LandingConfig from '../main/landing/LandingConfig';
import deeplinkConfig from '../main/deeplink/deeplinkConfig';
import PolicyConfig from '../main/policy/PolicyConfig';
import BlogConfig from '../main/blog/BlogConfig';
import PartnerConfig from '../main/partner/PartnerConfig';
import WelcomeConfig from '../main/welcome/WelcomeConfig';

const routeConfigs = [
  ...dashboardsConfigs,
  ...pagesConfigs,
  LandingConfig,
  BlogConfig,
  PartnerConfig,
  WelcomeConfig,
  deeplinkConfig,
  PolicyConfig,
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
  // {
  //   path: '/',
  //   element: <LandingConfig />
  // },
  // {
  //   path: 'loading',
  //   element: <FuseLoading />,
  // },
  {
    // path: '*',
    element: <Navigate to="pages/error/404" />,
  },
];

export default routes;
