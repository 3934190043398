import withReducer from 'app/store/withReducer';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Icon,
  Select,
  MenuItem,
  TextField,
  Input,
  Paper,
  CircularProgress,
} from '@mui/material';
// import './MangeBusiness.css';
import { useSnackbar } from 'notistack';
import {
  GoogleMap,
  Marker,
  LoadScript,
  StandaloneSearchBox,
  Autocomplete,
} from '@react-google-maps/api';
import axios from 'axios';
import ReactSelect from 'react-select';
import { FixedSizeList as List } from 'react-window';
import DeleteIcon from '@mui/icons-material/Delete';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PhoneInput from 'react-phone-input-2';
import { publicIpv4 } from 'public-ip';
import history from '@history';
import LandingQuery from '../query/landingDetail';
import { countryData, getDecryptData, getEncryptedData, handleApiRequest } from '../common/common';

const AddBusinessDetails = (prop) => {
  const {
    reviewPlateFormData,
    setActiveStepNumber,
    setBusinessDetails,
    setReviewPlateFormData,
    packageTypeId,
    setTrialSteps,
    clientId,
  } = prop;
  const { t } = useTranslation();
  const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const lib = ['places'];
  const [isZomato, setIsZomato] = useState(false);
  const [isYelp, setIsYelp] = useState(false);
  const [isGoogle, setIsGoogle] = useState(false);
  const [isBusinessFetch, setIsBusinessFetch] = useState(false);
  const [yelpData, setYelpData] = useState([]);
  const [autocompleteMap, setAutocompleteMap] = useState({});
  const [searchBusinessName, setSearchBusinessName] = useState();
  const [googleData, setGoogleData] = useState({});
  const [countryId, setCountryId] = useState();
  const [addBusinessData, setAddBusinessData] = useState({
    address: '',
    businessName: '',
    websiteUrl: '',
    businessType: '',
    phone: '',
    googlePhoneNumber: '',
    businessEmail: '',
    email: '',
    country: '',
    city: '',
    businessImage: '',
    reviewPlatformId: '',
  });
  const [zomatoBusiness, setZomatoBusiness] = useState();
  const [zomatoVerified, setZomatoVerified] = useState(false);
  const [zomatoUrl, setZomatoUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [stateName, setStateName] = useState();
  const [stateData, setStateData] = useState([]);
  const [refreshIcon, setRefreshIcon] = useState(false);
  const [businessType, setBusinessType] = useState([]);
  const [stateId, setStateId] = useState(0);
  const [zipCode, setZipCode] = useState();
  const [ipBasedData, setIpBasedData] = useState(null);
  const [dataAvailable, setDataAvailable] = useState(false);
  const [isValidZomatoUrl, setIsValidZomatoUrl] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [isGoogleBusinessAdd, setIsGoogleBusinessAdd] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({ lat: 34.0522342, lng: -118.2436849 });
  const [selectedMapFullAddress, setSelectedMapFullAddress] = useState();
  const [number, setNumber] = useState(1);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const convertUppercase = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  useEffect(() => {
    if (!ipBasedData) {
      setLoading(true);
      const ipAddress = publicIpv4();
      const ipAddressData = async () => {
        const payload = {
          query: LandingQuery.getLocation,
          variables: { data: getEncryptedData(`{"ipAddress": "${await ipAddress}"}`) },
        };
        const locationResponse = await handleApiRequest(payload);
        if (locationResponse?.payload?.data?.getLocation?.data) {
          const locationData = locationResponse?.payload?.data?.getLocation?.data;
          if (locationData) {
            setIpBasedData(JSON.parse(getDecryptData(locationData)));
          }
        }
      };
      ipAddressData();
    }
  }, [ipBasedData]);

  const CustomMenuList = (props) => {
    const itemHeight = 35;
    const { options, children, maxHeight, getValue } = props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * itemHeight;
    if (options.length > 0) {
      return (
        <div>
          <List
            height={maxHeight}
            itemCount={children.length}
            itemSize={itemHeight}
            initialScrollOffset={initialOffset}
          >
            {({ index, style }) => (
              <div style={style} className="bg-white relative">
                {children[index]}
              </div>
            )}
          </List>
        </div>
      );
    }
    return <div className="p-16">Loading....</div>;
  };

  useEffect(() => {
    const data = countryData(ipBasedData?.countryId);
    setCountryId(data?.phoneCountry);
  }, [ipBasedData?.countryId]);

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    ) {
      return;
    }
    if (!loading) {
      setLoading(true);
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  }, [loading]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    const fetchCountryData = async () => {
      const payload = {
        query: LandingQuery.getStates,
        variables: {
          countryId: parseInt(ipBasedData?.countryId, 10),
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.getStates?.status === 200) {
        setStateData(result.getStates?.data);
      }
    };
    fetchCountryData();
  }, [ipBasedData?.countryId]);

  useEffect(() => {
    const fetchBusinessTye = async () => {
      const payload = {
        query: LandingQuery.getBusinessType,
      };
      const result = await handleApiRequest(payload);
      if (result?.getBusinessType?.status === 200) {
        setBusinessType(
          result?.getBusinessType?.data.map((idx) => ({
            value: idx.id,
            label: idx.bType,
          }))
        );
      }
    };
    fetchBusinessTye();
  }, []);

  useEffect(() => {
    const fetchYelpData = async (page) => {
      try {
        const payload = {
          query: LandingQuery.searchYelpBusiness,
          variables: {
            location: 'USA',
            search: searchBusinessName,
            pageNumber,
          },
        };
        const result = await handleApiRequest(payload);
        setLoading(false);
        if (result?.searchYelpBusiness?.status === 200) {
          setDataAvailable(result?.searchYelpBusiness?.data?.length === 0 && true);
          setYelpData((prevData) => [...prevData, ...result.searchYelpBusiness.data]);
        }
      } catch (error) {
        console.error('Error fetching Yelp data:', error);
      }
    };
    if (!dataAvailable && parseInt(countryId, 10) === 1) {
      fetchYelpData();
    }
  }, [pageNumber, dataAvailable, countryId, searchBusinessName]);

  const onPlacesChanged = async () => {
    setDataLoading(true);
    if (autocompleteMap !== null) {
      const place = autocompleteMap?.getPlace();
      setCurrentLocation({
        lat: place.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng(),
      });

      if (!place?.place_id) {
        enqueueSnackbar('Please select data in dropdown', {
          variant: 'error',
          autoHideDuration: 3000,
        });
        setDataLoading(false);
        return;
      }

      setSelectedMapFullAddress(place.formatted_address);
      let formattedAddress = '';

      try {
        const googleBusinessData = await axios({
          url: process.env.REACT_APP_AI_GOOGLE_PLACE,
          method: 'post',
          maxBodyLength: Infinity,
          headers: {
            'Content-Type': 'application/json',
          },
          data: JSON.stringify({
            place_id: place?.place_id,
            place_name: place.name,
          }),
        });

        const googleDetails = googleBusinessData?.data?.data?.data;
        formattedAddress = place.formatted_address;
        const tempPhone = place?.international_phone_number
          ?.split(' ')
          .join('')
          .replace(/[^0-9]/g, '');

        if (googleBusinessData?.status === 200) {
          await setGoogleData({
            address: formattedAddress,
            businessName: place?.name,
            url: place?.url,
            businessImage: googleDetails?.profile_image,
            rating: place?.rating,
            userTotalRating: place?.user_ratings_total,
            phone: {
              ...(tempPhone && {
                phoneNumber: tempPhone,
                countryCode: place?.international_phone_number?.split(' ')[0],
                isoCode: place?.international_phone_number?.split(' ')[0] === '+91' ? 'in' : 'us',
              }),
            },
            country: googleDetails?.country,
          });
        } else {
          enqueueSnackbar('An error occurred while fetching the place details.', {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      } catch (error) {
        enqueueSnackbar('An error occurred while fetching the place details.', {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }

      const isAllFalse = reviewPlateFormData?.every((ele) => ele.isBusinessDetailsAdded === false);
      const data = reviewPlateFormData?.map((ele) => {
        if (ele.name === 'google') {
          return {
            ...ele,
            address: formattedAddress,
            businessName: place?.name,
            placeId: place.place_id,
            url: place.url,
            isBusiness: true,
            isBusinessDetailsAdded: isAllFalse || ele.isBusinessDetailsAdded,
          };
        }
        return ele;
      });
      setReviewPlateFormData(data);
      setDataLoading(false);
    }
  };

  const onPlaceSearch = (event) => {
    setSelectedMapFullAddress(event.target.value);
  };

  const onLoad = (autocompleteData) => {
    setAutocompleteMap(autocompleteData);
  };

  const onDragChanged = async (event) => {
    if (event) {
      const newLocation = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setCurrentLocation(newLocation);
    }
  };
  const handleRemoveBusiness = async (data) => {
    const removeBusinessInformation = reviewPlateFormData?.map((ele) => {
      if (ele.id === data.id) {
        return {
          logo: ele.logo,
          name: ele.name,
          id: ele.id,
          isBusinessDetailsAdded: false,
        };
      }
      if (ele.businessName) {
        return {
          ...ele,
          isBusinessDetailsAdded: true,
        };
      }
      return ele;
    });

    const findBusinessDetails = await removeBusinessInformation?.find(
      (ele) => ele.isBusinessDetailsAdded === true
    );
    if (findBusinessDetails) {
      addBusinessInformation(findBusinessDetails);
    }
    const findData = reviewPlateFormData?.find((item) => item?.id === data?.id);
    if (findData?.isBusinessDetailsAdded === true) {
      setAddBusinessData();
    }
    if (data.name === 'google') {
      setGoogleData();
    }
    if (data.name === 'zomato') {
      setZomatoUrl();
      setZomatoVerified(false);
      setZomatoBusiness();
    }
    setReviewPlateFormData(removeBusinessInformation);
  };
  const handleBusinessLocation = async (data) => {
    setNumber(1);
    if (data.name === 'zomato') {
      setIsZomato(true);
    }
    if (data.name === 'google') {
      setIsGoogle(true);
    }
    if (data.name === 'yelp') {
      setIsYelp(true);
      setPageNumber(1);
      const payload = {
        query: LandingQuery.searchYelpBusiness,
        variables: {
          location: 'USA',
          search: searchBusinessName,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.searchYelpBusiness?.data) {
        setYelpData(result?.searchYelpBusiness?.data);
      } else {
        enqueueSnackbar(result?.searchYelpBusiness?.message, {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
    }
  };

  const addBusinessInformation = async (businessData, yelpValues) => {
    try {
      setIsGoogleBusinessAdd(true);
      let url = {};
      if (businessData?.name === 'google') {
        setIsGoogle(false);
        url = {
          url: googleData?.url,
        };
      }
      if (businessData?.name === 'zomato') {
        setIsZomato(false);
        url = {
          place_name: `${businessData?.businessName},${businessData?.address}`,
        };
      }
      if (businessData?.name === 'yelp') {
        setIsYelp(false);
        url = {
          place_name: `${businessData?.businessName},${businessData?.address}`,
        };
      }
      const businessDetails = await axios({
        url:
          businessData?.name === 'google'
            ? process.env.REACT_APP_AI_GOOGLE_BUSINESS_TYPE
            : process.env.REACT_APP_AI_YELP_ZOMATO_BUSINESS_TYPE,
        method: 'post',
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(url),
      });
      setNumber(2);
      if (businessDetails?.data?.status === 200) {
        const data = businessDetails.data?.data;
        const findBusinessType = businessType?.filter(
          (item) =>
            item?.label === (businessData?.name === 'google' ? data?.business_type : data?.type)
        );
        if (businessData?.name !== 'google') {
          const phoneNumberPattern = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
            data?.phone_number
          );
          setIsPhoneNumberValid(phoneNumberPattern);
        } else if (businessData?.name === 'google') {
          const phoneData = googleData?.phone;
          const checkPhoneNumber =
            phoneData?.phoneNumber &&
            phoneData?.phoneNumber.includes(phoneData?.countryCode?.slice(1))
              ? phoneData?.phoneNumber.slice(phoneData?.countryCode?.slice(1).length)
              : phoneData?.phoneNumber;
          const phoneNumberPattern = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
            checkPhoneNumber
          );
          setIsPhoneNumberValid(phoneNumberPattern);
        }
        const phone = {
          phoneNumber: `${data?.country_code?.slice(1)}${data?.phone_number}`,
          countryCode: data?.country_code,
          isoCode: data?.iso_code,
        };
        setAddBusinessData({
          ...addBusinessData,
          reviewPlatformId: businessData?.id,
          businessType: findBusinessType?.[0]?.value,
          businessName: businessData?.businessName,
          phone: businessData?.name === 'google' ? googleData?.phone : phone,
          googlePhoneNumber: businessData?.name === 'google' ? googleData?.phone : phone,
          websiteUrl: businessData?.url,
          address: businessData?.address,
          country:
            businessData?.name === 'google'
              ? googleData?.country
              : businessData?.name === 'zomato'
              ? data?.country
              : yelpValues?.location?.country,
          city:
            businessData?.name === 'google'
              ? googleData?.country
              : businessData?.name === 'zomato'
              ? zomatoBusiness?.city
              : yelpValues?.location?.city,
          businessImage:
            businessData?.name === 'google'
              ? googleData?.businessImage
              : businessData?.name === 'zomato'
              ? zomatoBusiness?.businessImage
              : yelpValues?.image_url,
        });
        setTimeout(() => {
          setNumber(3);
          setTimeout(() => {
            setIsBusinessFetch(true);
            setIsGoogleBusinessAdd(false);
          }, 1000);
        }, 2000);
      }
    } catch (error) {
      setIsBusinessFetch(false);
      enqueueSnackbar('An error occurred while fetching the place details.', {
        variant: 'error',
        autoHideDuration: 3000,
      });
      setIsGoogleBusinessAdd(false);
      skipZomatoBusiness();
    }
  };

  const addBusinessDetails = async (value, yelpValues) => {
    let result = [];
    if (value === 'yelp') {
      result = await handleYelpAddBusiness(yelpValues);
    }
    const arrayData = value === 'yelp' ? result : reviewPlateFormData;
    const checkBusinessData = await arrayData.find((ele) => ele.name === value);
    if (checkBusinessData?.isBusinessDetailsAdded === true) {
      addBusinessInformation(checkBusinessData, yelpValues);
    } else {
      if (value === 'google') {
        setIsGoogle(false);
      }
      if (value === 'zomato') {
        setIsZomato(false);
      }
      if (value === 'yelp') {
        setIsYelp(false);
      }
    }
  };

  const handleYelpAddBusiness = async (value) => {
    const isAllFalse = reviewPlateFormData?.every((ele) => ele.isBusinessDetailsAdded === false);
    const data = await reviewPlateFormData?.map((ele) => {
      if (ele.name === 'yelp') {
        return {
          ...ele,
          address: value?.location?.display_address.join(' '),
          businessName: value?.name,
          placeId: value?.id,
          url: value?.url,
          isBusiness: true,
          isBusinessDetailsAdded: isAllFalse || ele.isBusinessDetailsAdded,
        };
      }
      return ele;
    });
    setReviewPlateFormData(data);
    return data;
  };

  const selectState = async (state) => {
    setStateId(parseInt(state, 10) !== 0 ? state : 0);
    const findState = stateData.find((ele) => ele.id === state);
    setStateName(findState?.name || '');
    const payload = {
      query: LandingQuery.searchYelpBusiness,
      variables: {
        location: `USA, ${findState?.name || ''}, ${zipCode || ''}`,
        search: searchBusinessName,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.searchYelpBusiness?.status === 200) {
      setYelpData(result?.searchYelpBusiness?.data);
    }
  };

  const handleZipCode = async (value) => {
    if (value?.length < 7) {
      setZipCode(value);
      const payload = {
        query: LandingQuery.searchYelpBusiness,
        variables: {
          location: `USA, ${stateName || ''}, ${value}`,
          search: searchBusinessName,
        },
      };
      const result = await handleApiRequest(payload);
      if (result?.searchYelpBusiness?.status === 200) {
        setYelpData(result?.searchYelpBusiness?.data);
      }
    }
  };

  const handleSearchBusinessName = async (value) => {
    setSearchBusinessName(value || '');
    const payload = {
      query: LandingQuery.searchYelpBusiness,
      variables: {
        location: `USA, ${stateName || ''}, ${zipCode || ''}`,
        search: value,
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.searchYelpBusiness?.status === 200) {
      setYelpData(result?.searchYelpBusiness?.data);
    }
  };

  const cancelYelp = () => {
    const removeBusinessInformation = reviewPlateFormData?.map((ele) => {
      if (ele.name === 'yelp') {
        return {
          logo: ele.logo,
          name: ele.name,
          id: ele.id,
          isBusinessDetailsAdded: false,
        };
      }
      return ele;
    });
    setReviewPlateFormData(removeBusinessInformation);
    setIsYelp(false);
    setDataAvailable(false);
    setPageNumber(1);
  };

  const clearSearch = async () => {
    setSearchBusinessName('');
    const payload = {
      query: LandingQuery.searchYelpBusiness,
      variables: {
        location: `USA, ${stateName || ''}, ${zipCode || ''}`,
        search: '',
      },
    };
    const result = await handleApiRequest(payload);
    if (result?.searchYelpBusiness?.status === 200) {
      setYelpData(result?.searchYelpBusiness?.data);
    }
  };

  const verifyZomatoLink = async () => {
    setRefreshIcon(true);
    setIsValidZomatoUrl(false);
    const payload = {
      query: LandingQuery.validateZomatoUrl,
      variables: {
        url: zomatoUrl,
      },
    };
    const result = await handleApiRequest(payload);
    setRefreshIcon(false);
    if (result?.validateZomatoUrl?.status === 200 && result?.validateZomatoUrl?.data) {
      const businessDetails = result?.validateZomatoUrl?.data;
      setZomatoVerified(true);
      setZomatoBusiness({
        businessName: businessDetails?.name,
        url: businessDetails?.business_url,
        address: businessDetails?.address,
        businessImage: businessDetails?.business_image_url,
        city: businessDetails?.city_name,
      });
      const isAllFalse = reviewPlateFormData?.every((ele) => ele.isBusinessDetailsAdded === false);
      const data = reviewPlateFormData?.map((ele) => {
        if (ele.name === 'zomato') {
          return {
            ...ele,
            address: businessDetails?.address,
            businessName: businessDetails?.name,
            placeId: businessDetails?.res_id.toString(),
            url: businessDetails?.business_url,
            isBusiness: true,
            isBusinessDetailsAdded: isAllFalse || ele.isBusinessDetailsAdded,
          };
        }
        return ele;
      });
      setReviewPlateFormData(data);
    }
    if (result?.validateZomatoUrl?.data === null) {
      setIsValidZomatoUrl(true);
      enqueueSnackbar(result?.validateZomatoUrl?.message, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const handlePhoneChange = (event, data, value, formattedValue) => {
    const phoneNumber = event;
    const countryCode = `+${data.dialCode}`;
    const isoCode = data.countryCode;
    const checkPhoneNumber = phoneNumber.includes(countryCode?.slice(1))
      ? phoneNumber.slice(countryCode?.slice(1).length)
      : phoneNumber;
    const phoneNumberPattern = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(
      checkPhoneNumber
    );
    setAddBusinessData({
      ...addBusinessData,
      phone: {
        phoneNumber,
        countryCode,
        isoCode,
      },
    });

    setIsPhoneNumberValid(phoneNumberPattern);
  };

  const skipGoogleBusiness = () => {
    setIsGoogle(false);
    setGoogleData();
    const removeBusinessInformation = reviewPlateFormData?.map((ele) => {
      if (ele.name === 'google') {
        return {
          logo: ele.logo,
          name: ele.name,
          id: ele.id,
          isBusinessDetailsAdded: false,
        };
      }
      return ele;
    });
    setReviewPlateFormData(removeBusinessInformation);
  };

  const skipZomatoBusiness = () => {
    setIsZomato(false);
    const removeBusinessInformation = reviewPlateFormData?.map((ele) => {
      if (ele.name === 'zomato') {
        return {
          logo: ele.logo,
          name: ele.name,
          id: ele.id,
          isBusinessDetailsAdded: false,
        };
      }
      return ele;
    });
    setZomatoUrl();
    setZomatoVerified(false);
    setZomatoBusiness();
    setReviewPlateFormData(removeBusinessInformation);
  };

  const submitBusinessDetails = async () => {
    setRefreshIcon(true);
    const reviewPlateForm = reviewPlateFormData?.filter((item) => item.businessName);
    const phoneObj = {
      ...addBusinessData?.phone,
      phoneNumber: parseInt(
        addBusinessData?.phone?.phoneNumber?.slice(
          addBusinessData?.phone?.countryCode?.slice(1).length
        ),
        10
      ),
    };
    const googlePhoneObj = {
      ...addBusinessData?.phone,
      phoneNumber: parseInt(
        addBusinessData?.phone?.phoneNumber?.slice(
          addBusinessData?.phone?.countryCode?.slice(1).length
        ),
        10
      ),
    };
    const findCountry = countryData(addBusinessData?.country === 'India' ? 2 : 1);
    const businessDetail = {
      address: addBusinessData?.address,
      businessTypeId: parseInt(addBusinessData?.businessType, 10),
      countryId: parseInt(findCountry?.id, 10),
      email: addBusinessData?.email,
      name: addBusinessData?.businessName?.toString(),
      phoneNumber: phoneObj,
      googlePhoneNumber: googlePhoneObj,
      profileURL: addBusinessData?.businessImage || '',
      defaultReviewPlatformId: parseInt(addBusinessData?.reviewPlatformId, 10),
      website: addBusinessData?.websiteUrl,
      reviewSite: reviewPlateForm?.map((item) => ({
        placeId: item.placeId,
        reviewPlatformId: parseInt(item.id, 10),
        url: item.url,
      })),
    };
    setBusinessDetails(businessDetail);
    setTrialSteps(5);
  };

  const handleSkipStep = () => {
    setAddBusinessData('');
    setGoogleData('');
    setZomatoBusiness('');
    setTrialSteps(5);
  };

  return (
    <div className={`text-left ${parseInt(packageTypeId, 10) === 5 ? 'w-full' : ''}`}>
      <h3
        className={`text-24 font-bold mb-12 ${
          parseInt(packageTypeId, 10) === 5 ? 'text-center' : ''
        }`}
      >
        Add Business Details
      </h3>
      <Typography
        className={`block text-16 font-semibold leading-relaxed ${
          parseInt(packageTypeId, 10) === 5 ? 'text-center max-w-640 m-auto' : 'max-w-640'
        }`}
      >
        Expand your business reach by adding it to more platforms. Enhance visibility, boost
        engagement, and grow your success exponentially.
      </Typography>
      {!isGoogle && !isYelp && !isZomato && !isGoogleBusinessAdd && !isBusinessFetch && (
        <>
          {reviewPlateFormData?.length > 0 &&
            reviewPlateFormData?.map((item, index) => {
              return (
                <div
                  className="border-1 flex justify-between mt-28 items-center border-solid !border-darkgreen rounded-xl mx-auto md:p-20 p-10"
                  key={index}
                >
                  <div className="flex items-center">
                    <img
                      src={item?.logo}
                      alt={item?.name}
                      className="md:max-w-52 max-w-28 md:mr-28 mr-10 rounded-full"
                    />
                    <span className="block md:text-20 text-14 font-bold">
                      {convertUppercase(item?.name)}
                    </span>
                  </div>
                  <div className="flex items-center">
                    {item?.businessName && addBusinessData?.businessType && (
                      <>
                        <img
                          src="/assets/images/icon/check_circle.svg"
                          className="img-fluid"
                          alt="..."
                        />
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          className="flex items-center rounded-full h-40 md:text-16 font-medium disabled:text-black sm:min-w-112 bg-transparent border-darkgreen text-darkgreen md:mx-16 mx-6"
                          onClick={() => handleBusinessLocation(item)}
                        >
                          <span>Edit</span>
                        </Button>
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          className="flex items-center rounded-full h-40 w-40 disabled:text-black min-w-0 bg-transparent border-red text-darkgreen"
                          onClick={() => handleRemoveBusiness(item)}
                        >
                          <DeleteIcon className="text-24 text-red" />
                        </Button>
                      </>
                    )}
                    {(!item?.businessName || !addBusinessData?.businessType) && (
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        className="inline-block align-middle rounded-3xl md:text-16 py-1 px-20 font-medium disabled:text-black sm:min-w-160 min-h-40"
                        onClick={() => handleBusinessLocation(item)}
                      >
                        <span>Add Location</span>
                      </Button>
                    )}
                  </div>
                </div>
              );
            })}
        </>
      )}
      {isGoogle && (
        <div className="mt-28">
          <div className={`${googleData?.businessName ? 'md:flex' : ''} gap-20`}>
            <div className={`${googleData?.businessName ? 'md:w-3/4' : 'w-full'}`}>
              <LoadScript
                id="script-loader"
                googleMapsApiKey={key}
                libraries={lib}
                loadingElement={<div>Loading...</div>}
              >
                <GoogleMap
                  mapContainerStyle={{
                    height: '300px',
                    width: '100%',
                    position: 'relative',
                  }}
                  className="custom-height rounded-lg"
                  zoom={8}
                  center={currentLocation}
                >
                  <Marker position={currentLocation} onDragEnd={onDragChanged} draggable />
                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlacesChanged} zIndex="999">
                    <StandaloneSearchBox zIndex="999">
                      <input
                        type="text"
                        value={selectedMapFullAddress}
                        onChange={(e) => onPlaceSearch(e)}
                        placeholder="Search your business name here..."
                        style={{
                          boxSizing: `border-box`,
                          border: `1px solid transparent`,
                          width: `300px`,
                          height: `32px`,
                          padding: `0 12px`,
                          borderRadius: `5px`,
                          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                          fontSize: `14px`,
                          outline: `none`,
                          textOverflow: `ellipses`,
                          position: 'absolute',
                          margin: '0 auto',
                          top: '14px',
                          left: '0',
                          right: '0',
                          zIndex: '1',
                        }}
                      />
                    </StandaloneSearchBox>
                  </Autocomplete>
                </GoogleMap>
              </LoadScript>
            </div>
            {googleData?.businessName && (
              <div className="border-1 sm:w-320 h-full mt-20 md:mt-0 m-auto md:w-288 rounded-md p-8 border-solid !border-darkgreen">
                <img className="rounded-md h-160 w-full" src={googleData?.businessImage} alt="" />
                <Typography className="font-medium text-16 pt-8">
                  {googleData?.businessName}
                </Typography>
                <Typography className="text-12 py-8">{googleData?.address}</Typography>
                <div className="flex items-center">
                  <Typography className="font-medium text-14">
                    {googleData?.rating > 0 && (
                      <Icon className="text-16 text-yellow-800 align-middle mr-2 -mt-2">star</Icon>
                    )}{' '}
                    {googleData?.rating}{' '}
                    <span className="font-normal pl-4 text-14">
                      ({googleData?.userTotalRating} reviews)
                    </span>
                  </Typography>
                </div>
              </div>
            )}
          </div>
          <div className="text-right mt-48">
            <Button
              variant="outlined"
              color="secondary"
              className="md:text-16 font-medium disabled:text-black rounded-md mr-24 sm:min-w-160"
              aria-label="skip"
              onClick={() => skipGoogleBusiness()}
              type="button"
            >
              Skip
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className="md:text-16 font-medium disabled:text-black rounded-md sm:min-w-160"
              aria-label="confirm"
              disabled={!googleData?.businessName}
              onClick={() => addBusinessDetails('google')}
              type="button"
            >
              Next
              {dataLoading && (
                <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
              )}
            </Button>
          </div>
        </div>
      )}
      {isGoogleBusinessAdd && (
        <div className="bg-gray-50 mt-28 flex items-center h-400 justify-center">
          <div className="">
            <div className="flex w-256 m-auto relative">
              <div
                className={`border-b-4 w-full pl-20 relative ${
                  number !== 1 ? '!border-darkgreen' : '!border-gray'
                }`}
              >
                <div className="absolute left-0 text-center w-20 h-20 border border-darkgreen rounded-xl bg-darkgreen text-white">
                  1
                </div>
              </div>
              <div
                className={`border-b-4 w-full pl-20 relative ${
                  number === 3 ? '!border-darkgreen' : '!border-gray'
                }`}
              >
                <div
                  className={`absolute left-0 text-center w-20 h-20 ${
                    number !== 1 ? 'border border-darkgreen bg-darkgreen' : 'bg-gray'
                  } rounded-xl text-white`}
                >
                  2
                </div>
              </div>
              <div className={`pl-20 pt-12 relative ${number === 3 ? 'border-darkgreen' : ''}`}>
                <div
                  className={`absolute left-0 top-0 text-center w-20 h-20 ${
                    number === 3 ? 'border border-darkgreen bg-darkgreen' : 'bg-gray'
                  } rounded-xl text-white`}
                >
                  3
                </div>
              </div>
            </div>
            <Typography className="text-darkgreen text-center py-28 font-bold text-16">
              Fetching Data
            </Typography>
            <Typography className="text-black font-medium text-center pb-8 text-16">
              Please wait while we process your request
            </Typography>
            <img className="w-480 m-auto" src="/assets/images/icon/Transferfilegif.gif" alt="" />
          </div>
        </div>
      )}
      {isBusinessFetch &&
        reviewPlateFormData?.length > 0 &&
        reviewPlateFormData?.map((data, index) => {
          return (
            <div key={index}>
              {data?.isBusinessDetailsAdded && (
                <div className="mt-48">
                  <div className="bg-gray-50 p-24">
                    <Typography className="text-20 font-bold">Business Details</Typography>
                    <hr className="border-1 my-20 !border-gray border-solid" />
                    <div className="flex gap-28">
                      <div className="w-full">
                        <Typography className="text-16 mb-8">
                          Business Name
                          <span className="text-red">*</span>
                        </Typography>
                        <TextField
                          className="w-full"
                          placeholder="Enter your business name..."
                          value={addBusinessData?.businessName}
                          onChange={(e) => {
                            setAddBusinessData({
                              ...addBusinessData,
                              businessName: e.target.value,
                            });
                          }}
                          size="small"
                        />
                      </div>
                      <div className="w-full">
                        <Typography className="text-16 mb-8">Website URL</Typography>
                        <TextField
                          className="w-full"
                          placeholder="Enter your website url..."
                          value={addBusinessData?.websiteUrl}
                          onChange={(e) => {
                            setAddBusinessData({
                              ...addBusinessData,
                              websiteUrl: e.target.value,
                            });
                          }}
                          size="small"
                        />
                      </div>
                    </div>
                    <div className="flex gap-28 my-8">
                      <div className="w-full">
                        <Typography className="text-16 mb-8">Business Type</Typography>
                        <ReactSelect
                          labelId="businessType"
                          placeholder="Select your business type"
                          id="businessType"
                          name="businessType"
                          minMenuHeight="45px"
                          onChange={(e) => {
                            setAddBusinessData({
                              ...addBusinessData,
                              businessType: e.value,
                            });
                          }}
                          value={businessType.find((op) => {
                            return (
                              parseInt(op.value, 10) === parseInt(addBusinessData.businessType, 10)
                            );
                          })}
                          options={businessType}
                          components={{
                            MenuList: CustomMenuList,
                          }}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              minHeight: '42px',
                            }),
                          }}
                        />
                      </div>
                      <div className="w-full">
                        <Typography className="text-16 mb-8">
                          Phone
                          <span className="text-red">*</span>
                        </Typography>
                        <PhoneInput
                          placeholder="Enter your phone number..."
                          inputStyle={{
                            width: '100%',
                            height: '40px',
                          }}
                          className="w-full"
                          name="phone_number"
                          required
                          size="small"
                          value={addBusinessData.phone?.phoneNumber?.toString()}
                          country={addBusinessData?.phone?.isoCode || countryId}
                          enableSearch="true"
                          onChange={handlePhoneChange}
                        />
                      </div>
                    </div>
                    <div className="flex gap-28">
                      <div className="w-full">
                        <Typography className="text-16 mb-8">Business Email</Typography>
                        <TextField
                          className="w-full"
                          placeholder="Enter your email..."
                          size="small"
                          value={addBusinessData.email}
                          onChange={(e) => {
                            setAddBusinessData({
                              ...addBusinessData,
                              email: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="w-full">
                        <Typography className="text-16 disabled:text-black disabled:font-semibold text-black mb-8">
                          Country
                        </Typography>
                        <Typography className="border rounded flex items-center pl-12 h-40 border-gray border-solid">
                          {addBusinessData?.country}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div className="text-right mt-28">
                    <Button
                      variant="outlined"
                      color="secondary"
                      className="md:text-16 font-medium hover:bg-darkgreen disabled:bg-gray disabled:text-black bg-darkgreen text-white rounded-md mr-24 sm:min-w-160"
                      aria-label="skip"
                      disabled={
                        !addBusinessData?.businessName ||
                        !addBusinessData?.businessType ||
                        !addBusinessData?.phone?.phoneNumber ||
                        !addBusinessData?.websiteUrl ||
                        !isPhoneNumberValid
                      }
                      onClick={() => {
                        setIsBusinessFetch(false);
                      }}
                      type="button"
                    >
                      Add Location
                    </Button>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      {isYelp && (
        <div>
          <div className="p-24 mt-28 rounded-lg bg-gray-200">
            <div className="h-288 overflow-scroll" onScroll={handleScroll}>
              <div className="flex gap-16 w-full">
                <Paper
                  initial={{ y: -20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                  className="flex items-center w-full sm:w-1/2 px-8 border border-solid border-gray-500 rounded-8 shadow-none"
                >
                  <Input
                    placeholder="Search your business name here..."
                    className="flex flex-1 mx-8 text-black text-16"
                    disableUnderline
                    name="searchText"
                    fullWidth
                    inputProps={{
                      'aria-label': 'Search',
                    }}
                    onChange={(e) => handleSearchBusinessName(e.target.value)}
                    value={searchBusinessName}
                  />
                  {searchBusinessName ? (
                    <Icon
                      color="action"
                      className="cursor-pointer"
                      onClick={() => {
                        clearSearch();
                      }}
                    >
                      close
                    </Icon>
                  ) : (
                    <Icon color="action">search</Icon>
                  )}
                </Paper>
                <Typography className="w-full sm:w-1/4 bg-white border flex pl-12 items-center rounded !border-gray-400">
                  United States
                </Typography>
                <Select
                  className={`bg-white w-full sm:w-1/4 ${
                    parseInt(stateId, 10) === 0 ? 'text-gray' : ''
                  }`}
                  value={stateId}
                  onChange={(e) => selectState(e.target.value)}
                  sx={{
                    '& .MuiSelect-select': {
                      display: 'flex !important',
                      alignItems: 'center !important',
                    },
                  }}
                  placeholder="select country"
                  size="small"
                >
                  <MenuItem className="text-gray" value="0">
                    Select State
                  </MenuItem>
                  {stateData?.length > 0 &&
                    stateData?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </div>
              <div className="mt-12">
                <TextField
                  placeholder="Zip code"
                  value={zipCode}
                  type="number"
                  onChange={(e) => handleZipCode(e.target.value)}
                  className="w-full bg-white"
                  size="small"
                />
              </div>
              {yelpData?.length > 0 ? (
                yelpData?.map((item) => {
                  return (
                    <div className="sm:flex justify-between items-center p-16 bg-white rounded mt-16">
                      <div className="flex items-center gap-24">
                        <div>
                          <img
                            className="w-120 rounded rounded-md h-80"
                            src={item.image_url}
                            alt=""
                          />
                        </div>
                        <div className="text-start">
                          <Typography className="font-bold">{item.name}</Typography>
                          <Typography className="text-black pt-4 text-14">
                            <LocationOnOutlinedIcon className="text-gray-500 text-20" />{' '}
                            {item.location?.display_address.join(' ')}
                          </Typography>
                        </div>
                      </div>
                      <div className="text-right mt-24 sm:mt-0">
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          className="inline-block align-middle rounded-3xl md:text-16 py-1 px-20 font-medium disabled:text-black sm:min-w-160 min-h-40"
                          onClick={() => addBusinessDetails('yelp', item)}
                        >
                          <span>Add Business</span>
                        </Button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <Typography className="font-semibold pt-80 text-16 flex items-center justify-center">
                  No data available
                </Typography>
              )}
            </div>
          </div>
          <div className="text-right mt-48">
            <Button
              variant="outlined"
              color="secondary"
              className="md:text-16 font-medium disabled:text-black rounded-md mr-24 sm:min-w-160"
              aria-label="skip"
              onClick={() => cancelYelp()}
              type="button"
            >
              Skip
            </Button>
          </div>
        </div>
      )}
      {!isGoogle && !isYelp && !isZomato && !isGoogleBusinessAdd && !isBusinessFetch && (
        <div className="text-right mt-48">
          {!clientId && (
            <Button
              variant="outlined"
              color="secondary"
              className="md:text-16 font-medium disabled:text-black rounded-md mr-24 sm:min-w-160"
              aria-label="skip"
              onClick={() => handleSkipStep()}
              type="button"
            >
              Skip
            </Button>
          )}
          <Button
            variant="contained"
            color="secondary"
            className="md:text-16 font-medium disabled:bg-darkgreen-A300 disabled:text-white rounded-md sm:min-w-160"
            aria-label="confirm"
            disabled={!addBusinessData?.businessName}
            onClick={() => submitBusinessDetails()}
            type="button"
          >
            Save & Next
            {refreshIcon && (
              <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
            )}
          </Button>
        </div>
      )}
      {isZomato && (
        <div className="p-24 mt-28 rounded-lg bg-gray-200">
          <div className="text-start">
            <Typography className="text-black text-16 font-medium mb-8">
              Enter Zomato Business URL{' '}
              <a
                href="https://www.zomato.com/"
                target="_blank"
                className="!bg-transparent !text-darkgreen underline text-12"
                rel="noreferrer"
              >
                (Go to Zomato)
              </a>
            </Typography>
            <TextField
              className="w-full text-black text-16 font-medium bg-white"
              value={zomatoUrl}
              onChange={(e) => {
                setZomatoUrl(e.target.value);
                setZomatoVerified(false);
              }}
              placeholder="https://"
              size="small"
            />
            {zomatoVerified && zomatoUrl ? (
              <>
                <Typography className="text-black text-16 font-medium my-8">
                  Business Name
                </Typography>
                <Typography className="text-black border text-black text-14 bg-white border-gray rounded border solid py-10 pl-12 my-8">
                  {zomatoBusiness?.businessName}
                </Typography>
              </>
            ) : (
              <>
                <Button
                  className="border border-solid rounded-4xl mt-20 bg-darkgreen hover:bg-darkgreen disabled:border-none disabled:text-black disabled:bg-gray text-white font-medium px-40"
                  onClick={() => verifyZomatoLink()}
                  disabled={refreshIcon || !zomatoUrl}
                >
                  Verified Link
                  {refreshIcon && (
                    <CircularProgress size={24} className="text-darkgreen absolute mx-auto" />
                  )}
                </Button>
                <img
                  src="/assets/images/icon/zomatoGuide.png"
                  className={`img-fluid w-full mt-32 ${
                    parseInt(packageTypeId, 10) === 5 ? 'max-w-256' : 'max-w-360'
                  }`}
                  alt="..."
                />
              </>
            )}
            {isValidZomatoUrl && (
              <Typography className="text-red mt-16 font-medium text-14">
                <WarningAmberIcon className="text-red" />
                The entered Zomato business link is incorrect. Please check and try again.
              </Typography>
            )}
            <div className="text-right mt-60">
              <Button
                variant="outlined"
                color="secondary"
                className="md:text-16 font-medium disabled:text-black rounded-md mr-24 sm:min-w-160"
                aria-label="skip"
                onClick={() => skipZomatoBusiness()}
                type="button"
              >
                Skip
              </Button>
              {zomatoVerified && (
                <Button
                  variant="contained"
                  color="secondary"
                  className="md:text-16 px-28 font-medium rounded-md inline-block align-middle"
                  onClick={() => addBusinessDetails('zomato')}
                >
                  <span>Add Review Site</span>
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddBusinessDetails;
