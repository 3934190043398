import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_ENDPOINT,
});

export const getLocation = createAsyncThunk('welcome/getLocation', async (data) => {
  try {
    const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
    return await response.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
});

export const getCorporateWebsitesCustomersSay = createAsyncThunk(
  'welcome/getCorporateWebsitesCustomersSay',
  async (data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
      return await response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);

export const getCorporateWebsitesTrustedBusiness = createAsyncThunk(
  'welcome/getCorporateWebsitesTrustedBusiness',
  async (data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
      return await response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);

export const sendOTPOnPhoneNumberVerification = createAsyncThunk(
  'welcome/sendOTPOnPhoneNumberVerification',
  async (data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_AUTH_ENDPOINT, { ...data });
      return await response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);

export const verifyPhoneNumberOTP = createAsyncThunk(
  'welcome/verifyPhoneNumberOTP',
  async (data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_AUTH_ENDPOINT, { ...data });
      return await response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);
export const verifyEmailOTP = createAsyncThunk('welcome/verifyEmailOTP', async (data) => {
  try {
    const response = await axios.post(process.env.REACT_APP_AUTH_ENDPOINT, { ...data });
    return await response.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
});
export const checkAndVerifyPhoneNumber = createAsyncThunk(
  'welcome/checkAndVerifyPhoneNumber',
  async (data) => {
    const getTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    instance.defaults.headers.common.isrequestfrom = true;
    instance.defaults.headers.common.timeZone = getTimeZone;
    try {
      const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
      return await response.data?.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);

export const signupV3 = createAsyncThunk('welcome/signUp', async (data) => {
  const getTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  instance.defaults.headers.common.isrequestfrom = true;
  instance.defaults.headers.common.timeZone = getTimeZone;
  try {
    const response = await instance.post(process.env.REACT_APP_AUTH_ENDPOINT, { ...data });
    return await response.data?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
});

export const checkEmail = createAsyncThunk('welcome/checkEmail', async (data) => {
  const getTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  instance.defaults.headers.common.isrequestfrom = true;
  instance.defaults.headers.common.timeZone = getTimeZone;
  try {
    const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
    return await response.data?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
});

export const sendOTPOnEmailVerification = createAsyncThunk(
  'welcome/sendOTPOnEmailVerification',
  async (data) => {
    instance.defaults.headers.common.isrequestfrom = true;
    try {
      const response = await instance.post(process.env.REACT_APP_AUTH_ENDPOINT, { ...data });
      return await response.data?.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);

export const saveNewsletterSubscription = createAsyncThunk(
  'welcome/sendOTPOnEmailVerification',
  async (data) => {
    instance.defaults.headers.common.isrequestfrom = true;
    try {
      const response = await instance.post(process.env.REACT_APP_AUTH_ENDPOINT, { ...data });
      return await response.data?.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);

export const getPackages = createAsyncThunk('welcome/getPackages', async (data) => {
  try {
    const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
    return await response.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
});

export const getReviewPlatforms = createAsyncThunk('welcome/getReviewPlatforms', async (data) => {
  try {
    const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
    return await response.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
});

export const validateZomatoUrl = createAsyncThunk('welcome/validateZomatoUrl', async (data) => {
  try {
    const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
    return await response.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
});

export const searchYelpBusiness = createAsyncThunk('welcome/searchYelpBusiness', async (data) => {
  try {
    const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
    return await response.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
});

export const getCountries = createAsyncThunk('welcome/getCountries', async (data) => {
  try {
    const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
    return await response.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
});

export const getStates = createAsyncThunk('welcome/getStates', async (data) => {
  try {
    const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
    return await response.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
});

const WelcomeSlice = createSlice({
  name: 'welcome',
  initialState: {
    initialState: {},
  },
  reducers: {
    resetWelcome: (state) => {
      if (state) {
        state.getCorporateWebsitesCustomersSayData = {};
        state.sendOTPOnPhoneNumberVerificationData = {};
        state.getCorporateWebsitesTrustedBusinessData = {};
        state.checkAndVerifyPhoneNumberData = {};
        state.signupV3Data = {};
        state.verifyEmailOTPData = {};
        state.checkEmailData = {};
        state.sendOTPOnEmailVerificationData = {};
        state.getPackagesData = {};
      }
    },
  },
  extraReducers: {
    [getCorporateWebsitesCustomersSay.fulfilled]: (state, action) => ({
      ...state,
      getCorporateWebsitesCustomersSayData: action.payload,
    }),
    [sendOTPOnPhoneNumberVerification.fulfilled]: (state, action) => ({
      ...state,
      sendOTPOnPhoneNumberVerificationData: action.payload,
    }),
    [getCorporateWebsitesTrustedBusiness.fulfilled]: (state, action) => ({
      ...state,
      getCorporateWebsitesTrustedBusinessData: action.payload,
    }),
    [checkAndVerifyPhoneNumber.fulfilled]: (state, action) => ({
      ...state,
      checkAndVerifyPhoneNumberData: action.payload,
    }),
    [signupV3.fulfilled]: (state, action) => ({
      ...state,
      signupV3Data: action.payload,
    }),
    [verifyEmailOTP.fulfilled]: (state, action) => ({
      ...state,
      verifyEmailOTPData: action.payload,
    }),
    [checkEmail.fulfilled]: (state, action) => ({
      ...state,
      checkEmailData: action.payload,
    }),
    [sendOTPOnEmailVerification.fulfilled]: (state, action) => ({
      ...state,
      sendOTPOnEmailVerificationData: action.payload,
    }),
    [getPackages.fulfilled]: (state, action) => ({
      ...state,
      getPackagesData: action.payload,
    }),
  },
});
export const { resetWelcome, extraReducers } = WelcomeSlice.actions;
export default WelcomeSlice.reducer;
