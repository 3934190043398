import { memo, useContext, useState, useEffect, useMemo, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import withReducer from 'app/store/withReducer';
import { useDispatch, useSelector } from 'react-redux';
import './BrandLogo.css';
import reducer from './store';
import { getCorporateWebsitesTrustedBusiness } from './store/landingSlice';
import LandingQuery from '../query/landingDetail';

function BrandLogo() {
  const getTrustedBusinessData = useSelector(
    ({ landingReducer }) =>
      landingReducer?.landing?.getCorporateWebsitesTrustedBusinessData?.data
        ?.getCorporateWebsitesTrustedBusiness?.data || []
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const payloadData = {
      query: LandingQuery.getCorporateWebsitesTrustedBusiness,
      variables: {
        pageNumber: 1,
        pageSize: 10,
      },
    };
    dispatch(getCorporateWebsitesTrustedBusiness(payloadData));
  }, [dispatch]);

  return (
    <div>
      <Swiper
        breakpoints={{
          319: {
            slidesPerView: 2,
          },
          590: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 4,
          },
          992: {
            slidesPerView: 4,
          },
        }}
        slidesPerView={4}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
        className="swiperLogo"
      >
        {getTrustedBusinessData &&
          getTrustedBusinessData?.length > 0 &&
          getTrustedBusinessData?.map((trustBusiness, index) => (
            <SwiperSlide key={index}>
              <div className="mx-10 rounded-md max-w-208">
                <img src={trustBusiness?.icon} alt="..." className="w-auto mx-auto" />
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
}

export default withReducer('landingReducer', reducer)(BrandLogo);
