import Icon from '@mui/material/Icon';

function Footer() {
  return (
    <>
      <div
        className="bg-darkgreen md:mt-96 mt-24 pb-16 md:pb-52 pt-16 md:pt-60 contact"
        name="contacts"
      >
        <div className="mx-auto container md:container px-10">
          <h2
            className="text-center text-18 md:text-4xl text-white font-bold my-20 aos-init aos-animate"
            data-aos="fade-up"
          >
            Quick Reviews Promise
          </h2>
          <div className="row sm:flex items-center sm:-mx-24">
            <div className="sm:w-2/6 md:px-24 px-0 md:text-right text-center inline-block">
              <div className="text-left inline-block">
                <div
                  className="text-white md:text-20 mb-24 aos-init aos-animate"
                  data-aos="fade-right"
                >
                  <Icon className="text-22 mx-auto text-white align-middle mr-14">
                    phone_in_talk
                  </Icon>
                  <span className="font-medium ">+91 80875 69265</span>
                </div>
                <div
                  className="text-white md:text-20 mb-24 aos-init aos-animate"
                  data-aos="fade-right"
                >
                  <Icon className="text-22 mx-auto text-white align-middle mr-14">
                    phone_in_talk
                  </Icon>
                  <span className="font-medium ">+1 (408) 430-3590</span>
                </div>
                <div className="text-white md:text-20 aos-init aos-animate" data-aos="fade-right">
                  <Icon className="text-22 mx-auto text-white align-middle mr-14">mail</Icon>
                  <span className="font-medium ">info@quickreviews.click</span>
                </div>
              </div>
            </div>
            <div className="sm:w-2/6 md:px-24 px-0">
              <h2
                className="text-white text-16 md:text-24 md:text-center font-medium mb-32 mt-16 md:mt-0 aos-init aos-animate"
                data-aos="fade-up"
              >
                We make reviews easy
              </h2>
              <ul
                className="social mx-auto md:text-center mb-16 md:mb-0 aos-init aos-animate"
                data-aos="fade-up"
              >
                <li className="inline-block md:mx-15 mx-8">
                  <a
                    href="https://www.instagram.com/quickreviewsofficial"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      src="/assets/images/icon/instagram.svg"
                      alt="..."
                      className="mx-auto w-auto h-auto"
                    />
                  </a>
                </li>
                <li className="inline-block md:mx-15 mx-8">
                  <a
                    href="https://www.facebook.com/quickreviewsofficial"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      src="/assets/images/icon/facebook.svg"
                      alt="..."
                      className="mx-auto w-auto h-auto"
                    />
                  </a>
                </li>
                <li className="inline-block md:mx-15 mx-8">
                  <a
                    href="https://www.youtube.com/@QuickReviewsofficial"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      src="/assets/images/icon/youtube.svg"
                      alt="..."
                      className="mx-auto w-auto h-auto"
                    />
                  </a>
                </li>
                <li className="inline-block md:mx-15 mx-8">
                  <a
                    href="https://www.linkedin.com/company/quick-reviews/?viewAsMember=true"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      src="/assets/images/icon/linkedin.svg"
                      alt="..."
                      className="mx-auto w-auto h-auto"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="md:w-2/6 md:px-24 px-0">
              <div
                className="text-white md:text-20 mb-24 aos-init aos-animate"
                data-aos="fade-left"
              >
                <a href="/terms-of-service" role="button" target="_blank" className="text-white">
                  <Icon className="text-22 mx-auto text-white align-middle mr-14">info</Icon>
                  <span className="font-medium ">Terms And Condition</span>
                </a>
              </div>
              <div className="text-white md:text-20 aos-init aos-animate" data-aos="fade-left">
                <a href="/privacy-policy" role="button" target="_blank" className="text-white">
                  <Icon className="text-22 mx-auto text-white align-middle mr-14">privacy_tip</Icon>
                  <span className="font-medium ">Privacy Policy</span>
                </a>
              </div>
            </div>
          </div>
          <div
            className="text-white text-center text-18 md:text-4xl text-white font-bold my-20 aos-init aos-animate"
            data-aos="fade-up"
          >
            <a
              href="https://quickreviews.freshdesk.com/support/home"
              target="_blank"
              rel="noopener noreferrer nofollow"
              className="border-none"
              role="button"
            >
              <Icon className="text-xl mx-auto text-white align-middle mr-14">phone_in_talk</Icon>
              <span className="text-xl">Customer Support</span>
            </a>
          </div>
        </div>
      </div>

      <div className="bottom-footer bg-darkgreen py-10 md:py-16 border-t border-t-grey-200 contact">
        <div className="mx-auto container md:container px-10">
          <div className="flex justify-between items-center">
            <div className="text-center mx-auto text-14 text-white md:text-16">
              &copy; {new Date().getFullYear()} CloudPeak Technologies Pvt. Ltd. All Rights
              Reserved.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
