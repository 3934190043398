import withReducer from 'app/store/withReducer';
import { Typography } from '@mui/material';
import Header from './Header';
import Footer from './Footer';

function ServiceRecovery() {
  return (
    <div style={{ backgroundColor: '#FAFBFC' }}>
      <Header />
      <div className="bg-darkgreen lg:pb-96 pb-36 lg:pt-96 pt-36">
        <div className="container md:px-120 px-16">
          <div className="lg:flex justify-between items-center">
            <div>
              <h1 className="font-semibold md:text-48 text-36 text-white mb-28">
                Service Recovery
              </h1>
              <Typography className="text-white text-18 font-medium max-w-640">
                The feature of the Quick Reviews app that attempts to gather more information from
                customers who had a negative experience and offers them the option to speak to a
                staff member from the business represents a proactive approach to addressing
                customer concerns and improving their future experiences. Here are some key benefits
                of this capability.
              </Typography>
            </div>
            <img
              src="/assets/images/pages/ServiceRecovery.png"
              alt="bad"
              className="mx-auto w-auto h-auto max-w-360 relative -mb-288 hidden lg:inline-block"
            />
          </div>
        </div>
      </div>
      <div className="mb-48">
        <div className="container md:px-120 px-16">
          <h2 className="text-24 font-semibold md:mt-68 mt-36 mb-28">Key Benefits:</h2>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">01</span> Enhanced Customer Satisfaction:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            By offering dissatisfied customers the opportunity to provide more details about their
            negative experience and engage directly with a staff member, the Quick Reviews app
            demonstrates a commitment to resolving issues and satisfying customers. This proactive
            approach can help salvage customer relationships and prevent negative sentiment from
            spreading further.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">02</span> Opportunity for Service Recovery:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Providing customers with an avenue to communicate their grievances directly to a staff
            member allows businesses to initiate the service recovery process promptly. By
            addressing customer complaints in a timely and personalized manner, businesses can
            mitigate the impact of negative experiences and potentially turn dissatisfied customers
            into loyal advocates.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">03</span> Real-Time Feedback Loop:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            The option for customers to talk to a staff member through the Quick Reviews app creates
            a real-time feedback loop between businesses and their customers. This enables
            businesses to gain valuable insights into the root causes of customer dissatisfaction,
            identify trends or recurring issues, and implement corrective actions swiftly to improve
            overall service quality.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">04</span> Improved Online Reputation Management:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            By addressing complaints and resolving issues promptly through the Quick Reviews app,
            businesses can proactively manage their online reputation. Responding to negative
            feedback in a constructive and proactive manner demonstrates accountability and a
            commitment to customer satisfaction, which can enhance the business's reputation and
            credibility in the eyes of other potential customers.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">05</span> Customer-Centric Approach:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            The feature aligns with a customer-centric approach to business operations by
            prioritizing the needs and concerns of customers. By offering an opportunity for direct
            communication with staff members, businesses signal their dedication to listening to
            customer feedback, understanding their perspectives, and taking appropriate actions to
            address their concerns.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">06</span> Differentiation in the Marketplace:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            In a competitive marketplace, businesses that prioritize customer feedback and actively
            seek to address customer grievances differentiate themselves from competitors. The
            proactive approach facilitated by the Quick Reviews app can help businesses stand out by
            demonstrating their commitment to customer satisfaction and continuous improvement.
          </Typography>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ServiceRecovery;
