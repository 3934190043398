const getCorporateWebsitesCustomersSay = ` 
query ($pageNumber: Int, $pageSize: Int) {
	getCorporateWebsitesCustomersSay(pageNumber: $pageNumber , pageSize : $pageSize) {
        status
        message
        data {
          fullName
          profileImage
          summary
          designation
        }
      }    
	}
 
`;

const getCorporateWebsitesTrustedBusiness = ` 
query ($pageNumber: Int, $pageSize: Int) {
	getCorporateWebsitesTrustedBusiness(pageNumber: $pageNumber , pageSize : $pageSize) {
        status
        message
        data {
          id
          icon
          name
        }
      }    
	}
 
`;

const sendOTPOnPhoneNumberVerification = ` 
mutation ($countryCode: String, $isoCode: String, $phoneNumber: Float) {
	sendOTPOnPhoneNumberVerification(countryCode: $countryCode , isoCode : $isoCode, phoneNumber: $phoneNumber) {
        status
        message
      }    
	}
`;
const verifyPhoneNumberOTP = `
mutation($data: String!){
  verifyPhoneNumberOTP(data: $data){ 
        message
        status
    }
  }
`;
const checkAndVerifyPhoneNumber = `
query($phoneNumber: Float!){
  checkAndVerifyPhoneNumber(phoneNumber: $phoneNumber){ 
        message
        status
        available
    }
  }
`;

const signupV3 = `
mutation signupV3($data: String!) {
  signupV3(data:$data){
    status
    message
    refreshToken
    accessToken
  }
}
`;

const verifyOTP = `
mutation($data: String!){
    verifyOTP(data: $data){ 
        message
        status
    }
  }
`;

const checkEmail = `
query($email: String!){
  checkEmail(email: $email){ 
        message
        status
        available
    }
  }
`;
const sendOTPOnEmailVerification = `
mutation($email: String!){
  sendOTPOnEmailVerification(email: $email){ 
        message
        status
    }
  }
`;

const saveNewsletterSubscription = `
mutation($email: String!){
  saveNewsletterSubscription(email: $email){
    message
    status
  }
}
`;

const WelcomeQuery = {
  getCorporateWebsitesCustomersSay,
  sendOTPOnPhoneNumberVerification,
  getCorporateWebsitesTrustedBusiness,
  verifyPhoneNumberOTP,
  checkAndVerifyPhoneNumber,
  signupV3,
  verifyOTP,
  checkEmail,
  sendOTPOnEmailVerification,
  saveNewsletterSubscription,
};

export default WelcomeQuery;
