import { Button, Card, CardContent, CardMedia, Tooltip, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import history from '@history';
import { useCallback, useEffect, useRef, useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import moment from 'moment';
import FuseLoading from '@fuse/core/FuseLoading';
import Header from '../landing/Header';
import Footer from '../landing/Footer';
import LandingQuery from '../query/landingDetail';
import { getQuickReviewBlogs } from './store/BlogSlice';

const BlogDetails = () => {
  const dispatch = useDispatch();
  const [blogData, setBlogData] = useState();
  const [blog, setBlog] = useState([]);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const textRef = useRef(null);

  const fetchBlogData = useCallback(async () => {
    const payload = {
      query: LandingQuery.getQuickReviewBlogs,
      variables: {
        pageNumber: 1,
        pageSize: 2,
      },
    };
    const result = await dispatch(getQuickReviewBlogs(payload));
    if (result?.payload?.getQuickReviewBlogs?.status === 200) {
      setBlog(result?.payload?.getQuickReviewBlogs?.data);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchBlogData();
  }, [fetchBlogData]);

  useEffect(() => {
    setLoading(true);
    const fetchBlogDetailsById = async () => {
      const payload = {
        query: LandingQuery.getQuickReviewBlogs,
        variables: {
          blogId: parseInt(params?.id, 10),
        },
      };
      const result = await dispatch(getQuickReviewBlogs(payload));
      setLoading(false);
      if (result?.payload?.getQuickReviewBlogs?.status === 200) {
        setBlogData(result?.payload?.getQuickReviewBlogs?.data[0]);
      }
    };
    fetchBlogDetailsById();
  }, [dispatch, params?.id]);

  const handleRedirectBlog = (id) => {
    history.push(`/blog/${id}`);
  };

  if (loading) {
    return <FuseLoading />;
  }

  return (
    <div style={{ backgroundColor: '#FAFBFC' }}>
      <Header />
      {blogData && (
        <div>
          <div className="" dangerouslySetInnerHTML={{ __html: blogData?.description }} />
          <div className="pl-[26%] font-bold text-40 mt-24">
            <h2>You May Also Like</h2>
          </div>
          <div className="grid lg:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-20 container">
            {blog?.length > 0 &&
              blog?.map((item, index) => {
                return (
                  <BlogCard
                    key={index}
                    item={item}
                    textRef={textRef}
                    handleRedirectBlog={handleRedirectBlog}
                  />
                );
              })}
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

const BlogCard = ({ item, textRef, handleRedirectBlog }) => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const textElement = textRef.current;
    if (textElement) {
      setIsOverflowing(textElement.scrollWidth > textElement.clientWidth);
    }
  }, [item.title, textRef]);

  return (
    <Card className="rounded-0 w-full mt-40 m-auto">
      <CardMedia component="img" alt="green iguana" height="140" image={item.image} />
      <CardContent className="!pb-8">
        <div className="relative">
          <Typography
            className="mb-0 whitespace-nowrap overflow-hidden overflow-ellipsis"
            gutterBottom
            variant="h6"
            component="div"
            ref={textRef}
          >
            {item.title
              .split(' ')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')}{' '}
          </Typography>
          {isOverflowing && (
            <Tooltip
              className="mb-4"
              title={item.title
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')}
              placement="top-end"
            >
              <Button className="p-0 hover:bg-transparent min-w-[12px] min-h-[18px] max-h-[14px] w-[24px] absolute top-[9px] right-[1px]">
                .
              </Button>
            </Tooltip>
          )}
        </div>
        <div className="flex gap-24 my-12 items-center">
          <Typography className="text-[15px]" variant="body2" color="text.secondary">
            {moment(item.cts).format('MMMM DD, YY')}
          </Typography>
          <div className="flex items-center gap-6">
            <div className="border w-[8px] h-[8px] bg-darkgreen rounded-[23px] border-darkgreen border-solid" />
            <Typography className="text-[15px]" variant="body2" color="text.secondary">
              {item.postedBy}
            </Typography>
          </div>
        </div>
        <Button
          className="text-darkgreen hover:bg-white bg-white p-0"
          onClick={() => handleRedirectBlog(item.id)}
        >
          Read More <KeyboardArrowRightIcon />
        </Button>
      </CardContent>
    </Card>
  );
};

export default BlogDetails;
