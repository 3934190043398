import withReducer from 'app/store/withReducer';
import { Typography } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import reducer from './store';

function BusinessAnalytics() {
  return (
    <div style={{ backgroundColor: '#FAFBFC' }}>
      <Header />
      <div className="bg-darkgreen lg:pb-96 pb-36 lg:pt-96 pt-36">
        <div className="container md:px-120 px-16">
          <div className="lg:flex justify-between items-center">
            <div>
              <h1 className="font-semibold md:text-48 text-36 text-white mb-28">
                Business Analytics
              </h1>
              <Typography className="text-white text-18 font-medium max-w-640">
                The provision of deep insights into online reputation and the competitive landscape
                by Quick Reviews empowers business owners with valuable information to make informed
                decisions and drive improvements in various aspects of their operations. Here's how
                these actionable insights benefit business owners
              </Typography>
            </div>
            <img
              src="/assets/images/pages/BusinessAnalytics.png"
              alt="bad"
              className="mx-auto w-auto h-auto max-w-360 relative -mb-288 hidden lg:inline-block"
            />
          </div>
        </div>
      </div>
      <div className="mb-48">
        <div className="container md:px-120 px-16">
          <h2 className="text-24 font-semibold md:mt-68 mt-36 mb-28">Key Benefits:</h2>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">01</span> Comprehensive Online Reputation Management:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Quick Reviews provides business owners with a holistic view of their online reputation
            across multiple platforms and channels. By analyzing customer reviews, ratings, and
            sentiment, business owners can identify strengths, weaknesses, and areas for improvement
            in their products, services, and customer experiences.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">02</span> Competitive Benchmarking:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Quick Reviews enables business owners to benchmark their online reputation against
            competitors in their business vertical. By analyzing competitor reviews, ratings, and
            trends, business owners can gain valuable insights into industry standards, identify
            competitive advantages, and differentiate their offerings to attract and retain
            customers.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">03</span> Identifying Key Trends and Patterns:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Quick Reviews allows business owners to identify key trends, patterns, and emerging
            issues in customer feedback. By analyzing common themes, topics, and sentiments across
            reviews, business owners can identify recurring issues, address customer concerns, and
            implement proactive measures to improve customer satisfaction and loyalty.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">04</span> Prioritizing Actions and Investments:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Armed with actionable insights from Quick Reviews, business owners can prioritize
            actions, initiatives, and investments that have the greatest impact on building customer
            loyalty and improving their brand's perception. By focusing on areas that matter most to
            customers, business owners can allocate resources more effectively and drive meaningful
            improvements in their business outcomes.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">05</span> Tailoring Marketing and Communication
            Strategies:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Quick Reviews helps business owners tailor their marketing and communication strategies
            based on customer feedback, preferences, and perceptions. By leveraging insights from
            customer reviews, business owners can develop targeted messaging, campaigns, and
            promotions that resonate with their target audience and drive engagement, loyalty, and
            advocacy.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">06</span> Enhancing Product and Service Offerings:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Quick Reviews provides business owners with valuable feedback and insights to enhance
            their product and service offerings. By listening to customer feedback, identifying pain
            points, and understanding customer needs and preferences, business owners can innovate,
            iterate, and refine their offerings to better meet customer expectations and stay ahead
            of the competition.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">07</span> Monitoring and Measuring Progress:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Quick Reviews allows business owners to monitor and measure progress in managing their
            online reputation and improving their brand's perception over time. By tracking key
            performance indicators, metrics, and trends, business owners can assess the
            effectiveness of their initiatives, track changes in customer sentiment, and make
            data-driven decisions to drive continuous improvement and business success.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">08</span> Scalability:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            As businesses grow and expand their online presence, Quick Reviews can scale to
            accommodate the increasing number of review platforms they engage with. This scalability
            ensures that businesses can effectively manage their online reputation regardless of
            their size or industry.
          </Typography>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BusinessAnalytics;
