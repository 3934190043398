import CryptoJS from 'crypto-js';
import axios from 'axios';

const baseURL = process.env.REACT_APP_API_ENDPOINT;
const instance = axios.create({
  baseURL,
});

const forAuthBaseURL = process.env.REACT_APP_AUTH_ENDPOINT;
const instanceForAuth = axios.create({
  forAuthBaseURL,
});

export const getEncryptedData = (value = '') => {
  try {
    const publicKeyValue = process.env.REACT_APP_SECRET_KEY;
    return CryptoJS.AES.encrypt(value, publicKeyValue).toString();
  } catch (error) {
    throw new Error(error);
  }
};

export const getDecryptData = (value = '') => {
  try {
    const publicKeyValue = process.env.REACT_APP_SECRET_KEY;
    const decrypted = CryptoJS.AES.decrypt(value, publicKeyValue);
    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    throw new Error(error);
  }
};

export const handleApiRequest = async (data) => {
  try {
    const response = await instance.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
    return await response?.data?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const handleAuthRequest = async (data) => {
  try {
    const response = await instance.post(process.env.REACT_APP_AUTH_ENDPOINT, { ...data });
    return await response?.data?.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
  }
};

export const countryData = (countryId = '', phoneCountry = '') => {
  const country = [
    {
      id: 2,
      name: 'India',
      countryCode: 91,
      isoCode: 'IN', // Don't change this value
      isActive: true,
      currency: 'INR',
      symbol: '₹',
      flag: 'https://flagcdn.com/w320/in.png',
      phoneCountry: 'IN', // Don't change this value
    },
    {
      id: 1,
      name: 'United States',
      countryCode: 1,
      isoCode: 'USA', // Don't change this value
      isActive: true,
      currency: 'USD',
      symbol: '$',
      flag: 'https://flagcdn.com/w320/us.png',
      phoneCountry: 'US', // Don't change this value
    },
  ];
  if (countryId) {
    return country.find((element) => {
      return element.id === countryId;
    });
  }
  if (phoneCountry) {
    return country.find((element) => {
      return element.isoCode === phoneCountry;
    });
  }
  return country;
};
