const getPackages = ` 
query($countryId: Int, $packageInterval: String) {
	getPackages(countryId: $countryId, packageInterval: $packageInterval) {
	  data {
		id
		image
		name
		startDate
		expireDate
		packageIndex
		packageDetails
		offerAmount
		amount
		currency
		stripePriceId
    packageTypeId
	  }
	  status
	  message
	}
  }  
`;
const saveQuickReviewContactUsData = `
mutation($name: String!, $countryCode: String!, $isoCode: String!, $phoneNumber: Float!, $email: String!, $message: String!) {
	saveQuickReviewContactUsData(
	  name: $name
	  phoneNumber: {
		countryCode: $countryCode
		isoCode: $isoCode
		phoneNumber: $phoneNumber
	  }
	  email: $email
	  message: $message
	) {
	  status
	  message
	}
  }
  `;

const getPlansAndFeaturesData = `
query {
  getPlansAndFeaturesData {
    data {
      heading {
        index
        packageTypeId
        planName
      }
      featuresData {
        index
        featureName
        description
        value {
          index
          packageTypeId
          value
        }
      }
    }
    status
    message
  }
}
`;

const getLocation = `
query getLocation($data: String!) {
  getLocation(data: $data) {
    message
    status
    data
    id
  }
}
`;

const getTestimonial = `
query getTestimonial{
  getTestimonial {
    data {
      uts
      cts
      designation
      id
      isActive
      name
      designation
      profile
      videoLink
      videoThumbnail
      text
    }
    message
    status
  }
}
`;

const getQuickReviewBlogs = `
query($blogId: Int, $pageNumber: Int, $pageSize:Int){
  getQuickReviewBlogs(blogId: $blogId, pageNumber:$pageNumber,pageSize:$pageSize){
    message
    status
    data{
      id
      image
      postedBy
      title
      cts
      isActive
      description
    }
  }
}
`;

const getCorporateWebsitesTrustedBusiness = ` 
query ($pageNumber: Int, $pageSize: Int) {
	getCorporateWebsitesTrustedBusiness(pageNumber: $pageNumber , pageSize : $pageSize) {
        status
        message
        data {
          id
          icon
          name
        }
      }    
	}
 
`;

const getReviewPlatforms = `
 query($countryId: Int){
  getReviewPlatforms(countryId: $countryId){
    id 
    data{
      id 
      logo
      name
    }
    status
    message
    cts
    uts
  }
 }
`;

const validateZomatoUrl = `
mutation ($url: String!){
  validateZomatoUrl(url: $url){
    status
    message
    data {
      res_id
      name
      address
      city_name
      country_name
      business_image_url
      business_url
      overall_ratings {
        DINING {
          reviewCount
          rating
          rating_type
        }
        DELIVERY {
           reviewCount
          rating
          rating_type
        }
      }
      aggregate_rating {
        aggregate_rating
      }
    }
  }
}
`;

const searchYelpBusiness = `
query ($location: String!, $search: String, $pageNumber: Int, $pageSize: Int) {
  searchYelpBusiness(
    location: $location
    search: $search
    pageNumber: $pageNumber
    pageSize: $pageSize
  ) {
    data {
      alias
      categories {
        alias
        title
      }
      coordinates {
        latitude
        longitude
      }
      location {
        address1
        address2
        address3
        city
        country
        display_address
        state
        zip_code
      }
      display_phone
      distance
      id
      image_url
      is_closed
      name
      phone
      price
      rating
      review_count
      transactions
      url
    }
    message
    status
  }
}
`;

const getCountries = `
query {
  getCountries{ 
       data {
            id
            countryCode
            isActive
            isoCode
            name
            cts
          }
          message
          status
  }
}`;

const getStates = `
   query($countryId: Int!){
     getStates(countryId: $countryId){ 
          data {
               countryId
               id
               name
               cts
               shortName
             }
             message
             status
     }
   }
`;

const signupAgent = `
mutation($agencyAddress: String!, $agencyName: String!, $agencyUrl: String, $email: String!, $firstName: String!, $lastName: String!, $phoneNumber: PhoneNumberObjectV1!, $countryId: Int){
  signupAgent(agencyAddress: $agencyAddress, agencyName: $agencyName, agencyUrl: $agencyUrl, email: $email, firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber, countryId: $countryId){ 
             message
             status
     }
   }
`;

const checkAndVerifyEmailStatus = `
query($email: String!){
  checkAndVerifyEmailStatus(email: $email){ 
        message
        status
        available
    }
  }
`;

const checkAndVerifyPhoneNumber = `
query($phoneNumber: Float!){
  checkAndVerifyPhoneNumber(phoneNumber: $phoneNumber){ 
        message
        status
        available
    }
  }
`;

const getBusinessType = `
   query getBusinessType($isOnlineBusiness: Boolean){
    getBusinessType(isOnlineBusiness:$isOnlineBusiness){
        data {
          bType
          cts
          id
          isActive
        }
        message
        status
      }
   }
`;

const addBusinessDetailV2 = `mutation AddBusinessDetailV2($businessDetail: BusinessDetailV2!, $isClient: Boolean, $userId: Int) {
  addBusinessDetailV2(businessDetail: $businessDetail, isClient: $isClient, userId: $userId) {
    status
    message
  }
}
`;

const LandingQuery = {
  getPackages,
  saveQuickReviewContactUsData,
  getPlansAndFeaturesData,
  getLocation,
  getTestimonial,
  getQuickReviewBlogs,
  getCorporateWebsitesTrustedBusiness,
  getReviewPlatforms,
  validateZomatoUrl,
  searchYelpBusiness,
  getCountries,
  getStates,
  signupAgent,
  checkAndVerifyEmailStatus,
  checkAndVerifyPhoneNumber,
  getBusinessType,
  addBusinessDetailV2,
};

export default LandingQuery;
