import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getPackages = createAsyncThunk(
  'landing/getPackages',
  async (data, { getState, dispatch }) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const result = await response.data;
      return result;
    } catch (error) {
      const errMsg = error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
      return errMsg;
    }
  }
);
export const saveQuickReviewContactUsData = createAsyncThunk(
  'landing/saveQuickReviewContactUsData',
  async (data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
      const result = await response.data;
      return result;
    } catch (error) {
      const errMsg = error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
      return errMsg;
    }
  }
);

export const getPlansAndFeaturesData = createAsyncThunk(
  'landing/getPlansAndFeaturesData',
  async (data, { getState, dispatch }) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const result = await response.data;
      return result;
    } catch (error) {
      const errMsg = error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
      return errMsg;
    }
  }
);

export const getLocation = createAsyncThunk('payment/getLocation', async (data) => {
  try {
    const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
    return await response.data;
  } catch (error) {
    const errMsg = error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
    return errMsg;
  }
});

export const getTestimonial = createAsyncThunk('payment/getTestimonial', async (data) => {
  try {
    const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
    return await response.data;
  } catch (error) {
    const errMsg = error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
    return errMsg;
  }
});

export const getCorporateWebsitesTrustedBusiness = createAsyncThunk(
  'landing/getCorporateWebsitesTrustedBusiness',
  async (data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
      return await response.data;
    } catch (error) {
      return error.response
        ? error.response.data
        : { status: 'fail', message: 'Something went wrong' };
    }
  }
);

const landingSlice = createSlice({
  name: 'landing',
  initialState: {
    initialState: {},
  },
  reducers: {
    resetLanding: (state) => {
      if (state) {
        state.getPackagesData = {};
        state.saveQuickReviewContactUsDataDetail = {};
        // state.getTestimonialData = {};
      }
    },
  },
  extraReducers: {
    [getPackages.fulfilled]: (state, action) => ({
      ...state,
      getPackagesData: action.payload,
    }),
    [saveQuickReviewContactUsData.fulfilled]: (state, action) => ({
      ...state,
      saveQuickReviewContactUsDataDetail: action.payload,
    }),
    [getPlansAndFeaturesData.fulfilled]: (state, action) => ({
      ...state,
      getPlansAndFeaturesDataValue: action.payload,
    }),
    [getTestimonial.fulfilled]: (state, action) => ({
      ...state,
      getTestimonialData: action.payload,
    }),
    [getCorporateWebsitesTrustedBusiness.fulfilled]: (state, action) => ({
      ...state,
      getCorporateWebsitesTrustedBusinessData: action.payload,
    }),
  },
});
export const { resetLanding, extraReducers } = landingSlice.actions;
export default landingSlice.reducer;
