import React, { useEffect, useMemo, useState } from 'react';
import history from '@history';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import ReactFlagsSelect from 'react-flags-select';
import { useDispatch } from 'react-redux';
import { publicIpv4 } from 'public-ip';
import LandingQuery from '../query/landingDetail';
import { countryData, getDecryptData, getEncryptedData } from '../common/common';
import { getLocation } from '../landing/store/landingSlice';

function TermsService() {
  const customLabels = useMemo(
    () => ({
      IN: { primary: 'India' },
      US: { primary: 'USA' },
    }),
    []
  );
  const menus = [
    {
      id: 1,
      path: 'privacy-policy',
      name: 'Privacy Policy',
    },
    {
      id: 2,
      path: 'terms-of-service',
      name: 'Terms and Conditions',
    },
    {
      id: 3,
      path: 'cookie-policy',
      name: 'Cookie Policy',
    },
    {
      id: 4,
      path: 'end-use-license-agreement',
      name: 'End User License Agreement',
    },
    {
      id: 5,
      path: 'return-policy',
      name: 'Return Policy',
    },
    {
      id: 6,
      path: 'disclaimer',
      name: 'Disclaimer',
    },
    {
      id: 7,
      path: 'acceptable-use-policy',
      name: 'Acceptable Use Policy',
    },
    {
      id: 8,
      path: 'https://app.termly.io/notify/fc7fa893-43d5-48b0-adfb-bf5cfeeae679',
      name: 'Do Not Sell',
    },
    {
      id: 9,
      path: 'https://app.termly.io/notify/fc7fa893-43d5-48b0-adfb-bf5cfeeae679',
      name: 'Limit The Use Of My Sensitive Personal Information',
    },
  ];

  const routeParams = useParams();
  const dispatch = useDispatch();

  const [policyId, setPolicyId] = useState(1);
  const [ipBasedData, setIpBasedData] = useState();
  const [selected, setSelected] = useState();

  useEffect(() => {
    const ipAddress = publicIpv4();
    const ipAddressData = async () => {
      const payload = {
        query: LandingQuery.getLocation,
        variables: { data: getEncryptedData(`{"ipAddress": "${await ipAddress}"}`) },
      };
      const locationResponse = await dispatch(getLocation(payload));
      if (locationResponse?.payload?.data?.getLocation?.data) {
        const locationData = locationResponse?.payload?.data?.getLocation?.data;
        const isoCodeData = JSON.parse(getDecryptData(locationData));
        setIpBasedData(isoCodeData);
      }
    };
    ipAddressData();
  }, [dispatch, selected]);

  useEffect(() => {
    if (ipBasedData && !selected) {
      setSelected(ipBasedData?.isoCode);
    }
  }, [dispatch, selected, ipBasedData]);

  const handleTermsAndConditions = (id) => {
    if (id.startsWith('http')) {
      window.open(id, '_blank');
    } else {
      history.push(`${id}`);
      setPolicyId(id);
    }
  };
  return (
    <div className="">
      <div className="bg-darkgreen py-20 pl-80">
        <img src="assets/images/logo/quickreviewlogo.png" className="w-[110px]" alt="logo" />
      </div>
      <div className="md:p-80 p-8 h-[100%] bg-darkgreen-50">
        <div className="grid grid-cols-4 h-[100vh] overflow-scroll rounded-xl gap-4 md:p-40 p-8 bg-white">
          <div className="h-[100vh] sticky top-[0]">
            {menus?.map((menu, i) => (
              <div
                key={i}
                role="button"
                tabIndex={0}
                className={`py-8 ${routeParams.id === menu.path ? 'font-bold' : 'font-small'}`}
                onClick={() => handleTermsAndConditions(menu.path)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleTermsAndConditions();
                  }
                }}
              >
                {menu.name}
              </div>
            ))}
          </div>
          <div className="grid grid-cols-subgrid gap-4 col-span-3">
            <div className="flex">
              <ReactFlagsSelect
                className="react-flag-button  px-20"
                selected={selected || ipBasedData?.isoCode}
                onSelect={(code) => setSelected(code)}
                countries={['US', 'IN']}
                customLabels={customLabels}
              />
            </div>
            {routeParams.id === 'privacy-policy' && <PrivacyPolicy selected={selected} />}
            {routeParams.id === 'terms-of-service' && <TermsServices selected={selected} />}
            {routeParams.id === 'cookie-policy' && <CookiePolicy selected={selected} />}
            {routeParams.id === 'end-use-license-agreement' && (
              <EndUserLicenseAgreement selected={selected} />
            )}
            {routeParams.id === 'return-policy' && <ReturnPolicy selected={selected} />}
            {routeParams.id === 'disclaimer' && <Disclaimer selected={selected} />}
            {routeParams.id === 'acceptable-use-policy' && (
              <AcceptableUsePolicy selected={selected} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsService;

const PrivacyPolicy = ({ selected }) => {
  useEffect(() => {
    if (selected === 'IN') {
      const script = document.createElement('script');
      script.id = 'termly-jssdk';
      script.src = 'https://app.termly.io/embed-policy.min.js';

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    if (selected === 'US') {
      const script = document.createElement('script');
      script.id = 'termly-jssdk';
      script.src = 'https://app.termly.io/embed-policy.min.js';

      const termlyEmbedDiv = document.createElement('div');
      document.body.appendChild(script);
      document.body.appendChild(termlyEmbedDiv);

      return () => {
        document.body.removeChild(script);
        document.body.removeChild(termlyEmbedDiv);
      };
    }
    return () => {};
  }, [selected]);

  return (
    <div>
      {selected === 'IN' && (
        <div name="termly-embed" data-id="fc7fa893-43d5-48b0-adfb-bf5cfeeae679" />
      )}
      {selected === 'US' && (
        <div name="termly-embed" data-id="fc7fa893-43d5-48b0-adfb-bf5cfeeae679" />
      )}
    </div>
  );
};

const TermsServices = ({ selected }) => {
  useEffect(() => {
    if (selected === 'IN') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://app.termly.io/embed-policy.min.js';

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    if (selected === 'US') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://app.termly.io/embed-policy.min.js';

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    return () => {};
  }, [selected]);
  return (
    <div>
      {selected === 'IN' && (
        <div name="termly-embed" data-id="5b85d6bf-30c3-4014-a191-44aa3f42946d" />
      )}
      {selected === 'US' && (
        <div name="termly-embed" data-id="5b85d6bf-30c3-4014-a191-44aa3f42946d" />
      )}
    </div>
  );
};

const CookiePolicy = ({ selected }) => {
  useEffect(() => {
    if (selected === 'IN') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://app.termly.io/embed-policy.min.js';

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    if (selected === 'US') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://app.termly.io/embed-policy.min.js';

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    return () => {};
  }, [selected]);

  return (
    <div>
      {selected === 'IN' && (
        <div name="termly-embed" data-id="2fd27015-709c-4d08-b1ae-654df229ed55" />
      )}
      {selected === 'US' && (
        <div name="termly-embed" data-id="2fd27015-709c-4d08-b1ae-654df229ed55" />
      )}
    </div>
  );
};

const EndUserLicenseAgreement = ({ selected }) => {
  useEffect(() => {
    if (selected === 'IN') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://app.termly.io/embed-policy.min.js';

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    if (selected === 'US') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://app.termly.io/embed-policy.min.js';

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    return () => {};
  }, [selected]);

  return (
    <div>
      {selected === 'IN' && (
        <div name="termly-embed" data-id="b0117b0b-49fc-46cc-a5b5-25c206b7f694" />
      )}
      {selected === 'US' && (
        <div name="termly-embed" data-id="b0117b0b-49fc-46cc-a5b5-25c206b7f694" />
      )}
    </div>
  );
};

const ReturnPolicy = ({ selected }) => {
  useEffect(() => {
    if (selected === 'IN') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://app.termly.io/embed-policy.min.js';

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    if (selected === 'US') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://app.termly.io/embed-policy.min.js';

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    return () => {};
  }, [selected]);

  return (
    <div>
      {selected === 'IN' && (
        <div name="termly-embed" data-id="4ef974e5-20b0-4359-ab8d-f052770eac60" />
      )}
      {selected === 'US' && (
        <div name="termly-embed" data-id="4ef974e5-20b0-4359-ab8d-f052770eac60" />
      )}
    </div>
  );
};

const Disclaimer = ({ selected }) => {
  useEffect(() => {
    if (selected === 'IN') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://app.termly.io/embed-policy.min.js';

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    if (selected === 'US') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://app.termly.io/embed-policy.min.js';

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    return () => {};
  }, [selected]);

  return (
    <div>
      {selected === 'IN' && (
        <div name="termly-embed" data-id="55aabeb9-b0a0-452f-946b-4e9127416ac7" />
      )}
      {selected === 'US' && (
        <div name="termly-embed" data-id="55aabeb9-b0a0-452f-946b-4e9127416ac7" />
      )}
    </div>
  );
};

const AcceptableUsePolicy = ({ selected }) => {
  useEffect(() => {
    if (selected === 'IN') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://app.termly.io/embed-policy.min.js';

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    if (selected === 'US') {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://app.termly.io/embed-policy.min.js';

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    return () => {};
  }, [selected]);

  return (
    <div>
      {selected === 'IN' && (
        <div name="termly-embed" data-id="1c560ca2-7c47-4487-8c9b-a21ef8b42d39" />
      )}
      {selected === 'US' && (
        <div name="termly-embed" data-id="1c560ca2-7c47-4487-8c9b-a21ef8b42d39" />
      )}
    </div>
  );
};
