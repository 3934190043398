import withReducer from 'app/store/withReducer';
import { Typography } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import reducer from './store';

function SocialMediaEngagement() {
  return (
    <div style={{ backgroundColor: '#FAFBFC' }}>
      <Header />
      <div className="bg-darkgreen lg:pb-96 pb-36 lg:pt-96 pt-36">
        <div className="container md:px-120 px-16">
          <div className="lg:flex justify-between items-center">
            <div>
              <h1 className="font-semibold md:text-48 text-36 text-white mb-28">
                Social Media Engagement
              </h1>
              <Typography className="text-white text-18 font-medium max-w-640">
                The feature of Quick Reviews automatically generating social media posts based on
                customer reviews offers several benefits for businesses looking to maintain an
                active and engaging presence on social networks:
              </Typography>
            </div>
            <img
              src="/assets/images/pages/SocialMediaEngagement.png"
              alt="bad"
              className="mx-auto w-auto h-auto max-w-360 relative -mb-288 hidden lg:inline-block"
            />
          </div>
        </div>
      </div>
      <div className="mb-48">
        <div className="container md:px-120 px-16">
          <h2 className="text-24 font-semibold md:mt-68 mt-36 mb-28">Key Benefits:</h2>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">01</span> Efficient Content Creation:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            By automatically generating social media posts based on customer reviews, Quick Reviews
            streamlines the content creation process for businesses. This saves time and effort that
            would otherwise be spent brainstorming and drafting posts from scratch.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">02</span> Authenticity and Credibility:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Social media posts derived from genuine customer reviews carry authenticity and
            credibility. Sharing positive feedback and testimonials from satisfied customers
            reinforces trust in the business's products or services among its social media
            followers.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">03</span> User-Generated Content:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Leveraging customer reviews as content for social media posts creates user-generated
            content, which is highly valuable for businesses. User-generated content tends to
            resonate well with audiences and can foster a sense of community and engagement around
            the brand.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">04</span> Showcasing Customer Satisfaction:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Sharing positive reviews and testimonials on social media allows businesses to showcase
            customer satisfaction and highlight their strengths and successes. This can attract new
            customers and reassure existing ones about the quality and reliability of the business.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">05</span> Increased Engagement:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Social media posts derived from customer reviews have the potential to drive higher
            engagement levels among followers. Followers may be more inclined to like, share, or
            comment on posts that feature real-life experiences and testimonials from other
            customers.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">06</span> Reinforcing Brand Reputation:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Consistently sharing positive customer feedback on social media helps reinforce the
            brand's reputation and build a positive online presence. It demonstrates the business's
            commitment to customer satisfaction and fosters goodwill among followers.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">07</span> Easy Sharing and Amplification:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            The availability of pre-generated social media posts within the Quick Reviews platform
            makes it easy for businesses to review and share them on their social networks. This
            simplifies the process of maintaining an active social media presence and ensures that
            valuable customer feedback reaches a wider audience.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">08</span> Promoting Customer Advocacy:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Encouraging customers to share their positive experiences on social media through
            reviews can turn them into brand advocates. When businesses amplify these reviews
            through social media posts, it further encourages customer advocacy and word-of-mouth
            marketing.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">09</span> Data-Driven Insights:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Quick Reviews may offer insights and analytics on the performance of social media posts
            generated from customer reviews. Businesses can track metrics such as engagement rates,
            reach, and conversions to measure the effectiveness of their social media marketing
            efforts and optimize future campaigns.
          </Typography>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SocialMediaEngagement;
