import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card, CardContent, CardMedia, Tooltip, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import moment from 'moment';
import history from '@history';
import { useDispatch } from 'react-redux';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FuseLoading from '@fuse/core/FuseLoading';
import Header from '../landing/Header';
import Footer from '../landing/Footer';
import LandingQuery from '../query/landingDetail';
import { getQuickReviewBlogs } from './store/BlogSlice';

const Blog = () => {
  const dispatch = useDispatch();
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const textRef = useRef(null);

  const fetchBlogData = useCallback(async () => {
    setLoading(true);
    const payload = {
      query: LandingQuery.getQuickReviewBlogs,
    };
    const result = await dispatch(getQuickReviewBlogs(payload));
    setLoading(false);
    if (result?.payload?.getQuickReviewBlogs?.status === 200) {
      setBlogData(result?.payload?.getQuickReviewBlogs?.data);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchBlogData();
  }, [fetchBlogData]);

  if (loading) {
    return <FuseLoading />;
  }

  const handleChangePage = async (event, value) => {
    setPage(value + 1);
    const payload = {
      query: LandingQuery.getQuickReviewBlogs,
      variables: {
        pageNumber: value + 1,
        pageSize: rowsPerPage,
      },
    };
    const result = await dispatch(getQuickReviewBlogs(payload));
    setLoading(false);
    if (result?.payload?.getQuickReviewBlogs?.status === 200) {
      setBlogData(result?.payload?.getQuickReviewBlogs?.data);
    }
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
    const payload = {
      query: LandingQuery.getQuickReviewBlogs,
      variables: {
        pageNumber: page,
        pageSize: event.target.value,
      },
    };
    const result = await dispatch(getQuickReviewBlogs(payload));
    setLoading(false);
    if (result?.payload?.getQuickReviewBlogs?.status === 200) {
      setBlogData(result?.payload?.getQuickReviewBlogs?.data);
    }
  };

  const handleRedirectBlog = (id) => {
    history.push(`/blog/${id}`);
  };

  return (
    <div>
      <Header />
      <div className="bg-darkgreen relative">
        <h1 className="text-white  font-bold text-56 pt-[90px] text-center">
          Welcome to Our Blog!
        </h1>
        <Typography className="lg:max-w-[70%] sm:max-w-[85%] max-w-[90%] text-center text-white text-20 py-[80px] m-auto">
          Discover insights on business growth, review management, customer satisfaction, and more.
          Whether it's enhancing your online presence or mastering customer engagement, our articles
          unlock success in your industry.
        </Typography>
      </div>
      <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-48 container">
        {blogData?.length > 0 &&
          blogData?.map((item, index) => (
            <BlogCard
              key={index}
              item={item}
              textRef={textRef}
              handleRedirectBlog={handleRedirectBlog}
            />
          ))}
      </div>
      {/* <div className="container">
        <TablePagination
          component="div"
          count={100}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div> */}
      <Footer />
    </div>
  );
};

const BlogCard = ({ item, textRef, handleRedirectBlog }) => {
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const textElement = textRef.current;
    if (textElement) {
      setIsOverflowing(textElement.scrollWidth > textElement.clientWidth);
    }
  }, [item.title, textRef]);

  return (
    <Card className="rounded-0 w-full mt-40 m-auto">
      <CardMedia component="img" alt="green iguana" height="140" image={item.image} />
      <CardContent className="!pb-8">
        <div className="relative">
          <Typography
            className="mb-0 whitespace-nowrap overflow-hidden overflow-ellipsis"
            gutterBottom
            variant="h6"
            component="div"
            ref={textRef}
          >
            {item.title
              .split(' ')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')}{' '}
          </Typography>
          {isOverflowing && (
            <Tooltip
              className="mb-4"
              title={item.title
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')}
              placement="top-end"
            >
              <Button className="p-0 hover:bg-transparent min-w-[12px] min-h-[18px] max-h-[14px] w-[24px] absolute top-[9px] right-[1px]">
                .
              </Button>
            </Tooltip>
          )}
        </div>
        <div className="flex gap-24 my-12 items-center">
          <Typography className="text-[15px]" variant="body2" color="text.secondary">
            {moment(item.cts).format('MMMM DD, YY')}
          </Typography>
          <div className="flex items-center gap-6">
            <div className="border w-[8px] h-[8px] bg-darkgreen rounded-[23px] border-darkgreen border-solid" />
            <Typography className="text-[15px]" variant="body2" color="text.secondary">
              {item.postedBy}
            </Typography>
          </div>
        </div>
        <Button
          className="text-darkgreen hover:bg-white bg-white p-0"
          onClick={() => handleRedirectBlog(item.id)}
        >
          Read More <KeyboardArrowRightIcon />
        </Button>
      </CardContent>
    </Card>
  );
};

export default Blog;
