import withReducer from 'app/store/withReducer';
import { Typography } from '@mui/material';
import Header from './Header';
import Footer from './Footer';

function MoreEffectiveReviews() {
  return (
    <div style={{ backgroundColor: '#FAFBFC' }}>
      <Header />
      <div className="bg-darkgreen lg:pb-96 pb-36 lg:pt-96 pt-36">
        <div className="container md:px-120 px-16">
          <div className="lg:flex justify-between items-center">
            <div>
              <h1 className="font-semibold md:text-48 text-36 text-white mb-28">
                More Effective Reviews
              </h1>
              <Typography className="text-white text-18 font-medium max-w-640">
                The functionality of generating reviews based on various attributes rated by
                customers with Quick Reviews offers several benefits for both businesses and
                potential customers.
              </Typography>
            </div>
            <img
              src="/assets/images/pages/MoreEffectiveReviews.png"
              alt="bad"
              className="mx-auto w-auto h-auto max-w-360 relative -mb-288 hidden lg:inline-block"
            />
          </div>
        </div>
      </div>
      <div className="mb-48">
        <div className="container md:px-120 px-16">
          <h2 className="text-24 font-semibold md:mt-68 mt-36 mb-28">Key Benefits:</h2>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">01</span> Comprehensive Insight:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            By allowing customers to rate a business on different attributes such as service
            quality, product variety, cleanliness, and more, the generated reviews provide a more
            comprehensive insight into various aspects of the business. This helps potential
            customers make informed decisions based on a nuanced understanding of the business's
            strengths and areas for improvement.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">02</span> Higher Quality Reviews:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Reviews generated based on specific attributes are likely to be more detailed and
            focused compared to generic reviews. This results in higher quality feedback that is
            valuable for both the business and potential customers. Detailed reviews help businesses
            understand specific areas where they excel and areas where they need to improve.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">03</span> Enhanced Credibility:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            The structured approach to generating reviews based on rated attributes enhances the
            credibility of the reviews. Potential customers perceive reviews based on specific
            criteria as more reliable and trustworthy, as they provide a clearer picture of the
            business's performance across different dimensions.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">04</span> Targeted Improvement Opportunities:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Businesses can use the insights from attribute-based reviews to identify targeted
            improvement opportunities. For example, if customers consistently rate the business low
            on cleanliness, the business can focus its efforts on improving cleanliness standards to
            enhance customer satisfaction and reputation.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">05</span> Increased Engagement:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Social media posts derived from customer reviews have the potential to drive higher
            engagement levels among followers. Followers may be more inclined to like, share, or
            comment on posts that feature real-life experiences and testimonials from other
            customers.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">06</span> Competitive Advantage:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Businesses that actively collect and showcase attribute-based reviews through Quick
            Reviews gain a competitive advantage in the marketplace. They demonstrate a commitment
            to transparency, customer feedback, and continuous improvement, which can attract more
            customers and enhance brand reputation.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">07</span> Data-Driven Decision Making:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            The attribute-based reviews provides businesses with valuable data for decision-making.
            Businesses can analyze trends and patterns in customer ratings across different
            attributes to make informed strategic decisions that drive business growth and customer
            satisfaction.
          </Typography>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default MoreEffectiveReviews;
