import { memo, useContext, useState, useEffect, useMemo, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import {
  Card,
  CardContent,
  CardMedia,
  Dialog,
  Icon,
  Button,
  SwipeableDrawer,
  TablePagination,
  Typography,
} from '@mui/material';
import withReducer from 'app/store/withReducer';
import { useDispatch, useSelector } from 'react-redux';
import reducer from './store';
import { getTestimonial } from './store/landingSlice';
import LandingQuery from '../query/landingDetail';

function Testimonial() {
  const getTestimonialData = useSelector(
    ({ landingReducer }) =>
      landingReducer?.landing?.getTestimonialData?.data?.getTestimonial?.data || []
  );

  const dispatch = useDispatch();

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlayVideo = () => {
    setIsVideoPlaying(true);
    if (videoRef.current) {
      videoRef.current.play(); // Start video playback
    }
  };

  const handleVideoEnded = () => {
    setIsVideoPlaying(false);
  };

  useEffect(() => {
    const payload = {
      query: LandingQuery.getTestimonial,
    };
    dispatch(getTestimonial(payload));
  }, [dispatch]);

  return (
    <div>
      <Swiper
        breakpoints={{
          319: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 4,
          },
        }}
        slidesPerView={4}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {getTestimonialData &&
          getTestimonialData?.length > 0 &&
          getTestimonialData?.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div
                className="bg-grey-100 text-left shadow p-16 mx-10 rounded-md aos-init aos-animate"
                data-aos="flip-left"
              >
                <Icon className="text-yellow-700 text-24">star</Icon>
                <Icon className="text-yellow-700 text-24">star</Icon>
                <Icon className="text-yellow-700 text-24">star</Icon>
                <Icon className="text-yellow-700 text-24">star</Icon>
                <Icon className="text-yellow-700 text-24">star</Icon>
                <div className="text-left">
                  <Typography className="max-w-360 text-left font-medium text-16 mb-48 mt-20 min-h-224">
                    {testimonial?.videoLink ? (
                      <div className="video-container">
                        {isVideoPlaying ? (
                          <video
                            ref={videoRef}
                            className="block w-full rounded-md h-224"
                            controls
                            onEnded={handleVideoEnded}
                            onCanPlay={() => {
                              if (isVideoPlaying && videoRef.current) {
                                videoRef.current.play();
                              }
                            }}
                          >
                            <source
                              src="https://quick-review.s3.us-east-1.amazonaws.com/user-image/1711598795_21.mp4"
                              type="video/mp4"
                            />
                            <track kind="captions" label="English" default />
                          </video>
                        ) : (
                          <div
                            className="cover-image relative"
                            role="button"
                            tabIndex={0}
                            onKeyDown={handlePlayVideo}
                            onClick={handlePlayVideo}
                          >
                            <div className="cover-image-overlay absolute top-0 left-0 w-full h-full z-10" />
                            <img
                              src={testimonial?.videoThumbnail}
                              className="max-h-224 w-full rounded-md"
                              alt={testimonial?.name}
                            />
                            <Button
                              variant="contained"
                              className="play-button bg-white text-darkgreen w-40 h-40 min-w-0 absolute top-1/2 left-1/2 z-20"
                            >
                              <Icon className="text-18">play_arrow</Icon>
                            </Button>
                          </div>
                        )}
                      </div>
                    ) : (
                      testimonial?.text
                    )}
                  </Typography>
                </div>
                <div className="flex">
                  <img
                    src={testimonial?.profile}
                    alt={testimonial?.name}
                    className="rounded-full w-60 h-60 mr-16"
                  />
                  <span className="text-left">
                    <h5 className="text-16 font-semibold text-darkgreen">{testimonial?.name}</h5>
                    <h6 className="text-12 font-medium">{testimonial?.designation}</h6>
                  </span>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
}

export default withReducer('landingReducer', reducer)(Testimonial);
