import authRoles from '../../auth/authRoles';
import DeepLink from './deeplink';
import DeepLinkSelectBusiness from './deeplinkSelectBusiness';
import DeepLinkSubscribe from './deeplinkSubscribe';

const DeeplinkConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: 'apps/:name?/:id?',
      element: <DeepLink />,
    },
    {
      path: 'subscribe',
      element: <DeepLinkSubscribe />,
    },
    {
      path: 'select-business',
      element: <DeepLinkSelectBusiness />,
    },
  ],
};

export default DeeplinkConfig;
