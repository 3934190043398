import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Cookies from 'js-cookie';

function CookieBar() {
  const [isCookieSet, setCookie] = useState(Cookies.get('cookieConsent'));

  const handleAcceptCookies = () => {
    Cookies.set('cookieConsent', true);
    setCookie(true);
  };

  const handleRejectCookies = () => {
    Cookies.remove('cookieConsent');
    setCookie(false);
  };

  if (isCookieSet || isCookieSet === false) {
    return null;
  }

  return (
    <div className="fixed left-0 bottom-0 right-0 bg-black text-grey-100 py-16 z-20">
      <div className="px-16 md:px-112">
        <div className="md:flex items-center justify-between">
          <p className="md:w-9/12 md:text-10 text-10">
            We use essential cookies to make our site work. With your consent, we may also use
            essential cookies to improve user experience and analyze website traffic. By clicking
            "Accept," you agree to our website's cookie use as described in our Cookie Policy. You
            can change your cookie settings at any time by clicking "Preferences."
          </p>
          <div className="md:w-3/12 md:text-right text-center md:mt-0 mt-10">
            <Button
              className="rounded-md mr-20"
              size="small"
              variant="outlined"
              color="inherit"
              type="button"
              onClick={handleRejectCookies}
            >
              Decline
            </Button>
            <Button
              className="rounded-md"
              size="small"
              variant="contained"
              color="secondary"
              type="button"
              onClick={handleAcceptCookies}
            >
              Accept
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CookieBar;
