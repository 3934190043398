import { Typography } from '@mui/material';
import React, { Component, Fragment } from 'react';
import history from '@history';

function DeepLinkSubscribe(props) {
  isQuickreviewsInstalled();
  return (
    <div className="content-wrapper">
      {/* <Header /> */}
      <section className="wrapper bg-soft-primary">
        <div
          className="sm:flex items-center justify-between mx-auto px-16 md:px-36 xl:px-10  min-h-screen"
          style={{ maxWidth: '1400px' }}
        >
          <div
            className="mt-16 sm:mt-0 sm:pr-40 xl:pr-0 sm:block flex items-center h-auto sm:h-auto mt-16 sm:mt-0"
            style={{ maxWidth: '770px' }}
          >
            <div>
              <h1 className="text-20 sm:text-28 md:text-60 font-medium md:mb-40 mb-20">
                Manage Your Online Reputation
              </h1>
              <Typography className="text-16 sm:text-20 md:text-28 md:mb-48 mb-28">
                Quick Reviews empowers businesses to enhance their online reputation and attract
                customers. Our app simplifies reviews, offers insights and provides tools for
                growth. Explore Quick Reviews to take control of your online presence.
              </Typography>
              <h3 className="text-16 sm:text-20 md:text-24 font-semibold">
                DON'T HESITATE, DOWNLOAD IT TODAY
              </h3>
              <div className="md:mt-40 mt-20 block">
                <span className="inline-block">
                  <a
                    className="rounded me-2 mr-10 d-inline-block"
                    rel="noopener noreferrer"
                    href="https://itunes.apple.com/app/quick-reviews/id6449814972"
                    target="_blank"
                  >
                    <img
                      src="/assets/images/icon/apple_store.png"
                      alt="bad"
                      className="mx-auto max-w-128 md:max-w-224 md:mr-48 mr-20"
                    />
                  </a>
                </span>
                <span className="inline-block">
                  <a
                    className="rounded me-2 mr-10 d-inline-block"
                    rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/details?id=reviewApp.dev"
                    target="_blank"
                  >
                    <img
                      src="/assets/images/icon/play_store.png"
                      alt="bad"
                      className="mx-auto max-w-128 md:max-w-224"
                    />
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div className="min-w-256">
            <img
              src="/assets/images/icon/app_mockup_small.svg"
              alt="bad"
              className="mx-auto sm:max-h-none"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

function isQuickreviewsInstalled() {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  if (isMobile) {
    history.push('emailfor://quickreviewsEmailSuccess');
  } else {
    history.push(`${process.env.REACT_APP_PORTAL}/your-plan`);
  }
}

export default DeepLinkSubscribe;
