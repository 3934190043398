import withReducer from 'app/store/withReducer';
import { Typography } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import reducer from './store';

function SupportForTeams() {
  return (
    <div style={{ backgroundColor: '#FAFBFC' }}>
      <Header />
      <div className="bg-darkgreen lg:pb-96 pb-36 lg:pt-96 pt-36">
        <div className="container md:px-120 px-16">
          <div className="lg:flex justify-between items-center">
            <div>
              <h1 className="font-semibold md:text-48 text-36 text-white mb-28">
                Support For Teams
              </h1>
              <Typography className="text-white text-18 font-medium max-w-640">
                The capability for users of Quick Reviews to invite others to their account offers
                several benefits for businesses.
              </Typography>
            </div>
            <img
              src="/assets/images/pages/SupportForTeams.png"
              alt="bad"
              className="mx-auto w-auto h-auto max-w-360 relative -mb-288 hidden lg:inline-block"
            />
          </div>
        </div>
      </div>
      <div className="mb-48">
        <div className="container md:px-120 px-16">
          <h2 className="text-24 font-semibold md:mt-68 mt-36 mb-28">Key Benefits:</h2>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">01</span> Collaborative Review Management:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            By inviting other users to their account, businesses can collaborate more effectively in
            managing reviews. They can assign specific team members to monitor and respond to
            reviews, ensuring a coordinated and timely approach to customer engagement.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">02</span> Shared Workload:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Inviting others to their account allows businesses to distribute the workload of review
            management among multiple team members. This helps prevent one person from becoming
            overwhelmed with the task and ensures that reviews are addressed promptly.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">03</span> Specialized Roles:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Businesses can assign different roles and permissions to invited users based on their
            responsibilities. For example, they can designate some users to focus on monitoring
            reviews, while others may be responsible for responding to customer feedback or
            analyzing review trends.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">04</span> Enhanced Customer Engagement:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            With multiple users managing the account, businesses can engage with customers more
            efficiently and effectively. They can respond to reviews in a timely manner, address
            customer concerns, and demonstrate responsiveness, ultimately improving customer
            satisfaction and loyalty.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">05</span> Improved Accountability:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Inviting users to the account creates a sense of accountability among team members. Each
            user is responsible for their assigned tasks related to review management, which helps
            ensure that reviews are not overlooked and that customer feedback is addressed promptly
            and appropriately.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">06</span> Knowledge Sharing:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Collaborating within the Quick Reviews App allows team members to share insights and
            best practices for managing online reviews effectively. This fosters a culture of
            continuous improvement and allows businesses to refine their review management
            strategies over time.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">07</span> Training Opportunities:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            Inviting users to the account provides an opportunity for training and skill development
            in review management and customer engagement. New team members can learn from more
            experienced users and quickly become proficient in using the app to manage reviews and
            monitor online reputation.
          </Typography>
          <h3 className="text-18 font-semibold mb-14">
            <span className="text-darkgreen">08</span> Scalability:
          </h3>
          <Typography className="max-w-640 text-15 font-medium mb-20">
            As businesses grow and expand, they can easily add new users to their account to
            accommodate the increasing workload of review management. This scalability ensures that
            the business can effectively manage its online reputation regardless of its size or
            industry.
          </Typography>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SupportForTeams;
