import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getQuickReviewBlogs = createAsyncThunk('blog/getQuickReviewBlogs', async (data) => {
  try {
    const response = await axios.post(process.env.REACT_APP_API_ENDPOINT, { ...data });
    return await response.data?.data;
  } catch (error) {
    const errMsg = error.response
      ? error.response.data
      : { status: 'fail', message: 'Something went wrong' };
    return errMsg;
  }
});

const BlogSlice = createSlice({
  name: 'blog',
  initialState: {
    initialState: {},
  },
  reducers: {
    resetBlog: (state) => {
      if (state) {
        state.getQuickReviewBlogsData = {};
      }
    },
  },
  extraReducers: {
    [getQuickReviewBlogs.fulfilled]: (state, action) => ({
      ...state,
      getQuickReviewBlogsData: action.payload,
    }),
  },
});
export const { resetBlog, extraReducers } = BlogSlice.actions;
export default BlogSlice.reducer;
